import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import { userState } from "../../interface/MainInterface";
import { useEffect, useState } from "react";

import "./LivePlayer.css";

interface propsType {
  userState: userState;
}
const LivePlayerFrame = (props: propsType) => {
  const { broadseq } = useParams(); // URL Parameter 로 방송순번 전달 받음
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const [frameUrl, setFrameUrl] = useState("");

  useEffect(() => {
    let streamUrl: string = "";
    if (queryParams.userId !== undefined && queryParams.custNo !== undefined) {
      streamUrl = `/stream/${broadseq}?userId=${queryParams.userId}&custNo=${queryParams.custNo}&livartBroadSeq=1`;
    } else {
      streamUrl = `/stream/${broadseq}?userId=tester@test.com&custNo=tester&livartBroadSeq=1`;
    }
    if (queryParams.mode !== undefined) {
      if (queryParams.mode === "rehearsal") streamUrl += "&mode=rehearsal";
    }
    setFrameUrl(streamUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.addEventListener("message", function (e) {
    if (e.data.msg === "goBroadList") window.close();
  });

  return (
    <>
      <div className="frame-root">
        {broadseq !== undefined && frameUrl !== "" && (
          <iframe id={"test"} src={frameUrl} width={"100%"} height={"100%"} frameBorder={0} scrolling="no" title="live_frame"></iframe>
        )}
      </div>
    </>
  );
};

export default LivePlayerFrame;
