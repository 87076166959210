import { useEffect, useState, forwardRef } from "react";

// MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

// Local CSS
import "./LiveNotice.css";

interface propsType {
  broad_info: any;
  isNotFloat: any;
  isFloat: any;
}

const VodNotice = (props: propsType, ref: any) => {
  const [noticeText, setNoticeText] = useState(""); // 롤링영역 공지사항 최신 텍스트
  const [openNotice, setOpenNotice] = useState(false); // 공지사항, Q&A 상세보기

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setNoticeText("");
      setOpenNotice(false);
    };
  }, []);

  // 부모 컴포넌트에서 공지사항 변경시 최신화
  useEffect(() => {
    let tmpNoticeText = props.broad_info.broad_notice;
    tmpNoticeText = tmpNoticeText.replaceAll("<br />", " ").replaceAll("\n", " ");
    setNoticeText(tmpNoticeText);
  }, [props.broad_info.broad_notice]);

  // 상세보기 Drawer Control
  const handleNoticeDrawerClose = () => {
    setOpenNotice(false);
    setTimeout(() => {
      props.isFloat();
    }, 200);
  };
  const handleNoticeDrawerOpen = () => {
    props.isNotFloat();

    setTimeout(() => {
      setOpenNotice(true);
    }, 200);
  };

  const AutoLink = (prop: any) => {
    const delimiter = /(https?:\/\/[^\s]+)/g;
    return (
      <>
        {prop.text.split(delimiter).map((word: any, index: number) => {
          const match = word.match(delimiter);
          if (match) {
            const url = match[0];
            return (
              <a
                key={`a-t-k-${index}`}
                style={{ textDecoration: "underline", color: "#FF376D" }}
                target="_blank"
                href={url.startsWith("http") ? url : `http://${url}`}
                rel="noreferrer"
              >
                {url}
              </a>
            );
          }
          return word;
        })}
      </>
    );
  };

  return (
    <>
      {noticeText.length > 0 ? (
        <div className={`live-notice-wrapper`}>
          <Box className={`live-notice-root`} onClick={handleNoticeDrawerOpen}>
            <Box className="live-notice-item">
              <Box component="img" className="live-notice-img" src="/images/notice.png" />
              <span>&nbsp;</span>
              <Typography
                className="live-notice-text"
                fontFamily="Noto Sans KR"
                fontWeight="700"
                fontSize="14px"
                fontStyle="normal"
                color="#FFFFFF"
              >
                {"공지사항"}
              </Typography>
            </Box>
          </Box>
          {/* 상세보기 */}
          <Drawer
            PaperProps={{
              elevation: 0,
              style: {
                borderTopRightRadius: "16px",
                borderTopLeftRadius: "16px",
              },
            }}
            anchor="bottom"
            open={openNotice}
            onClose={handleNoticeDrawerClose}
            className="live-more-body"
          >
            <Box
              sx={{
                width: "100%",
                height: "60vh",
                bgcolor: "while",
                borderRadius: "16px",
                position: "relative",
                padding: "18px",
                overflow: "hidden",
              }}
            >
              {/* 공지사항 상세보기 상단 */}
              <Box className="live-notice-drawer-top">
                <Box component="img" src="/images/modal-notice.png" className="live-notice-drawer-top-img" />
                <div className="live-notice-drawer-top-text">&nbsp;공지사항</div>
                <Box className="live-notice-drawer-close">
                  <Box component="img" src="/images/notice-close.svg" onClick={handleNoticeDrawerClose} />
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex", width: "100%", height: "100%", pb: "20px" }}>
                <Typography component="div" className="live-notice-content">
                  <Box sx={{ width: "100%", height: "100%" }} dangerouslySetInnerHTML={{ __html: props.broad_info.broad_notice }}></Box>
                </Typography>
              </Box> */}
              <Box className="live-notice-more" sx={{ display: "flex", width: "100%", height: "100%", whiteSpace: "pre-line" }}>
                <Typography component="div" className="live-notice-content">
                  <AutoLink text={props.broad_info.broad_notice} />
                </Typography>
              </Box>
            </Box>
          </Drawer>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(VodNotice);
