import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Storage } from "aws-amplify";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { Input, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface propsType {}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "32px",
  right: "32px",
  minHeight: "60vh",
  maxHeight: "90vh",
  transform: "translateY(-50.04%)",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
};

const ModalWinnerList = (props: propsType, ref: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [winnerList, setWinnerList] = useState<any>([]);

  useEffect(() => {
    setWinnerList([]);
    return () => {
      setOpenModal(false);
    };
  }, []);

  const open = useCallback((list: any) => {
    setWinnerList(list);
    setOpenModal(true);
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setWinnerList([]);
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            <Box sx={{ p: 2 }}>
              <Box sx={{ position: "absolute", top: 2, right: 2 }}>
                <Tooltip title="창닫기">
                  <IconButton
                    onClick={() => {
                      setWinnerList([]);
                      setOpenModal(false);
                    }}
                  >
                    <CloseIcon className="color-red" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Stack direction="column" spacing={2}>
                <Box>
                  <Typography>당첨자 명단</Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Stack direction={"column"} spacing={2}>
                    {winnerList.map((winner: any, index: number) => (
                      <Box key={`winner-${index}`} sx={{ display: "flex", flexDirection: "row", gap: 0 }}>
                        <Typography>{winner.user_id}</Typography>
                        <Typography sx={{ color: "#00000080" }}>{`(${winner.nick_name})`}</Typography>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default forwardRef(ModalWinnerList);
