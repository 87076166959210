import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import LiveHome from "./page/LiveHome";

import LivePlayer from "./page/LivePlayer/LivePlayer";
import LivePlayerAndroid from "./page/LivePlayer/LivePlayerAndroid";
import LivePlayerIOS from "./page/LivePlayer/LivePlayerIOS";
import LivePlayerFrame from "./page/LivePlayer/LivePlayerFrame";
import LivartShare from "./page/LivePlayer/share/LivartShare";

import Box from "@mui/material/Box";

import { userState } from "./interface/MainInterface";

interface propsType {
  userState: userState;
  webViewStatus: string;
}

const MainContainer = (props: propsType) => {
  useEffect(() => {
    const pathName: string[] = window.location.pathname.split("/");
    if (pathName.length > 2) {
      if (pathName[1] === "stream") {
        document.body.style.overscrollBehaviorY = "none";
        document.body.style.height = "100%";
        document.body.style.minHeight = "100%";
      } else {
        document.body.style.overscrollBehaviorY = "auto";
        document.body.style.height = "100%";
        document.body.style.minHeight = "100%";
      }
    } else {
      document.body.style.overscrollBehaviorY = "auto";
      document.body.style.height = "100%";
      document.body.style.minHeight = "100%";
    }

    window.scrollTo(0, 0);
  }, [window.location]);

  return (
    <Box id="MainContainer" sx={{ flex: 1, paddingY: 0, paddingX: 0, backgroundColor: "transparent" }}>
      <Routes>
        <Route path="/" element={<LiveHome userState={props.userState} />} />
        <Route path="/frame/:broadseq" element={<LivePlayerFrame userState={props.userState} />} />
        <Route path="/stream/:broadseq" element={<LivePlayer webViewStatus={props.webViewStatus} />} />
        <Route path="/androidFrame/:broadseq" element={<LivePlayerAndroid webViewStatus={props.webViewStatus} />} />
        <Route path="/iOSFrame/:broadseq" element={<LivePlayerIOS webViewStatus={props.webViewStatus} />} />
        <Route path="/LivartShare" element={<LivartShare />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Box>
  );
};

export default MainContainer;
