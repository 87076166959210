import { ApiBase, BaseParams } from "./api-base";

export class HttpGameApi extends ApiBase {
  onApiName(): string {
    return "GameApiName";
  }

  onUrlPath(): string {
    return "/game";
  }

  public async get_live_game_info(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_live_game_info";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_game_info(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_game_info";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_game_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_game_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_draw_result(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_draw_result";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async check_game(param: any) {
    let requestBody: any = param;
    requestBody.command = "check_game";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async apply_game(param: any) {
    let requestBody: any = param;
    requestBody.command = "apply_game";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_game_apply_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_game_apply_list";
    const response = this.requestPost(requestBody);
    return response;
  }
}
