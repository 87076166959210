import { useEffect, useState } from "react";
import dayjs from "dayjs";

// MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
// Interface
import { HttpClientApi } from "../../../../components/interface/client-rest-api";

// Local CSS
import "./LiveQnA.css";

interface propsType {
  newQnAMsg: any;
  broad_seq: any;
  iconSize: string;
  hidden_menu: boolean;
  isNotFloat: any;
  isFloat: any;
}

const LiveQnA = (props: propsType, ref: any) => {
  const clientApi = new HttpClientApi(); // API Class

  const [broadQnaList, setBroadQnaList] = useState<any>([]); // 방송 QNA LIST
  const [openQna, setOpenQna] = useState(false); // 공지사항, Q&A 상세보기

  useEffect(() => {
    getBroadQnaList(props.broad_seq);
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBroadQnaList([]);
      setOpenQna(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 방송 Q&A List 조회
  const getBroadQnaList = async (broad_seq: string) => {
    const broadQnaResult = await clientApi.list_broad_qna(broad_seq, "DESCEND");
    setBroadQnaList(broadQnaResult.result_body);
  };

  useEffect(() => {
    if (props.newQnAMsg !== null) {
      setBroadQnaList([props.newQnAMsg, ...broadQnaList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.newQnAMsg]);

  // 상세보기 Drawer Control
  const handleQnaDrawerClose = () => {
    setOpenQna(false);
    props.isFloat();
  };
  const handleQnaDrawerOpen = () => {
    getBroadQnaList(props.broad_seq);
    props.isNotFloat();

    setTimeout(() => {
      setOpenQna(true);
    }, 200);
  };

  const AutoLink = (prop: any) => {
    const delimiter = /(https?:\/\/[^\s]+)/g;
    return (
      <>
        {prop.text.split(delimiter).map((word: any, index: number) => {
          const match = word.match(delimiter);
          if (match) {
            const url = match[0];
            return (
              <a
                key={`a-t-k-${index}`}
                style={{ textDecoration: "underline", color: "#FF376D" }}
                target="_blank"
                href={url.startsWith("http") ? url : `http://${url}`}
                rel="noreferrer"
              >
                {url}
              </a>
            );
          }
          return word;
        })}
      </>
    );
  };

  return (
    <>
      <div>
        <Box sx={props.hidden_menu ? { display: "none" } : { display: "block" }} onClick={handleQnaDrawerOpen}>
          <img className="footer-right-icon" src="/images/btn-qna.png" alt="" />
        </Box>
        {/* 상세보기 */}
        <Drawer
          PaperProps={{ elevation: 0, style: { borderTopRightRadius: "16px", borderTopLeftRadius: "16px" } }}
          anchor="bottom"
          open={openQna}
          onClose={handleQnaDrawerClose}
          className="live-more-body"
        >
          <Box
            sx={{
              width: "100%",
              height: "60vh",
              bgcolor: "while",
              borderRadius: "16px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box sx={{ p: "18px" }}>
              <Box className="live-qna-drawer-top">
                <Box component="img" src="/images/modal-qna.png" className="live-qna-drawer-top-img" />
                <div className="live-qna-drawer-top-text">Q&A</div>
                <Box className="live-qna-drawer-close">
                  <Box component="img" src="/images/notice-close.svg" onClick={handleQnaDrawerClose} />
                </Box>
              </Box>
              {/* QNA 상세보기 */}
              <Box className="live-qna-more" sx={{ display: "flex", width: "100%" }}>
                <List className="live-qna-list" sx={{ paddingTop: "0px" }}>
                  {broadQnaList.map(
                    (obj: any, index: number) =>
                      obj.sk !== undefined && (
                        <div key={`qna${obj.sk}`}>
                          <ListItem disablePadding sx={{ alignItems: "start", marginTop: "10px" }}>
                            {/* Question Icon */}
                            <Box component="img" className="question-icon" src="/images/icon-question.png" />
                            <ListItemText
                              className="question-item"
                              primary={
                                <Typography
                                  paddingRight="10px"
                                  component="div"
                                  fontFamily="Noto Sans KR"
                                  fontWeight="700"
                                  fontSize="16px"
                                  fontStyle="normal"
                                  color="#111111"
                                  sx={{ wordBreak: "break-all" }}
                                >
                                  {obj.qContent}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  component="div"
                                  fontFamily="Noto Sans KR"
                                  fontWeight="400"
                                  fontSize="13px"
                                  fontStyle="normal"
                                  color="#999999"
                                >
                                  {obj.qUserName + " • " + dayjs(String(obj.qCreatedTimestamp)).format("YYYY.MM.DD, HH:mm")}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem disablePadding className="answer-list-item">
                            <ListItemText
                              primary={
                                <Typography
                                  component="div"
                                  fontFamily="Noto Sans KR"
                                  fontWeight="400"
                                  fontSize="14px"
                                  fontStyle="normal"
                                  color="#111111"
                                >
                                  <AutoLink text={obj.aContent} />
                                </Typography>
                              }
                            />
                          </ListItem>
                        </div>
                      )
                  )}
                </List>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </div>
    </>
  );
};

export default LiveQnA;
