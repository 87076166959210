/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
const awsmobile = {
  aws_project_region: "ap-northeast-2",

  Auth: {
    region: "ap-northeast-2",
    userPoolId: "ap-northeast-2_1g1a16NIK", // Please change this value.
    userPoolWebClientId: "13pj3tnktkvga5sh6dbv7i75at", // Please change this value.
    identityPoolId: "ap-northeast-2:6d4451f3-b7b7-4a52-82b5-c3ce46ad210d",
  },
  Storage: {
    AWSS3: {
      bucket: "livartlivedev-channelbackendstack-wa-ap-northeast-2-38100",
      region: "ap-northeast-2",
    },
  },
  aws_cloud_logic_custom: [
    {
      name: "MobileApiName",
      endpoint: "https://livart-api.livehyundai.com/client/",
      region: "ap-northeast-2",
    },
    {
      name: "LiveChannelApiName",
      endpoint: "https://livart-api.livehyundai.com/client/",
      region: "ap-northeast-2",
    },
    {
      name: "GameApiName",
      endpoint: "https://livart-api.livehyundai.com/client/",
      region: "ap-northeast-2",
    },
    {
      name: "StatisticApiName",
      endpoint: "https://livart-api.livehyundai.com/client/",
      region: "ap-northeast-2",
    },
    {
      name: "ChatApiName",
      endpoint: "https://m8kooc9e44.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "ChatInputApiName",
      endpoint: "https://8civ7j5nrh.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "ChatMngApiName",
      endpoint: "https://bbcyrpox53.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
  ],
};

export default awsmobile;
