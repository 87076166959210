import { API, Auth } from "aws-amplify";
import axios from "axios";
import dayjs from "dayjs";
import { appConfig, setAccessToken } from "../../config/Config";

export interface BaseParams {
  pk: string;
  broad_seq: string;
}

export interface BroadParams {
  broad_seq: string;
}

export abstract class ApiBase {
  protected apiName: string;
  protected urlPath: string;
  protected authToken: string | undefined;
  protected userId: string | undefined;
  protected userChatName: string | undefined;
  protected accessToken: string | undefined;
  protected expireIn = dayjs();

  abstract onApiName(): string;
  abstract onUrlPath(): string;

  constructor() {
    this.apiName = this.onApiName();
    this.urlPath = this.onUrlPath();

    this.authToken = undefined;
    this.userId = undefined;
    this.userChatName = undefined;
  }

  protected async getToken() {
    if (this.authToken === undefined) {
      const session = await Auth.currentSession();
      this.authToken = `Bearer ${session.getIdToken().getJwtToken()}`;
    }

    return this.authToken;
  }

  protected async getExternalToken() {
    const exCred = await this.getAccessToken();
    return `Bearer ${exCred}`;
  }

  protected async getUserId() {
    if (this.userId === undefined) {
      if (await this.fncCheckAuthenticated()) {
        let user = await Auth.currentAuthenticatedUser();
        this.userId = user?.attributes.email;
      } else {
        this.userId = "anonymous";
      }
    }
    return this.userId;
  }

  // Session Check
  protected fncCheckAuthenticated = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      return false;
    }
  };

  protected async getUserChatName() {
    if (this.userChatName === undefined) {
      const user = await Auth.currentAuthenticatedUser();
      this.userChatName = user.attributes["custom:chat_name"];
    }

    return this.userChatName;
  }

  protected async requestPost(requestBody: any): Promise<any> {
    let header: any = { Authorization: "abcd" };
    let authToken: string;

    if (this.apiName === "AdminApiName" || this.apiName === "ExternalApiName") {
      if (this.apiName === "ExternalApiName") authToken = await this.getExternalToken();
      else authToken = await this.getToken();

      header = {
        Authorization: authToken,
      };
    }
    const request = {
      headers: header,
      body: requestBody,
    };
    const response = await API.post(this.apiName, this.urlPath, request);
    return response;
  }

  protected async requestExtPost(requestUrl: string, requestBody: any): Promise<any> {
    try {
      let header: any = { timeout: 60000 };

      const response = await axios.post(requestUrl, requestBody, header);
      return response.data;
    } catch (error) {
      return { code: "500", response: { error_msg: "apiTimeout" } };
    }
  }

  protected async getAccessToken(): Promise<any> {
    const basicHeader = btoa(`${appConfig.app_client_id}:${appConfig.app_client_secret}`);
    const reqBody = `grant_type=client_credentials`;
    let expireDiff = 0;
    if (appConfig.access_token_expire_time !== undefined) {
      expireDiff = dayjs().diff(appConfig.access_token_expire_time, "s");
    }

    if (appConfig.access_token === undefined || expireDiff > 0) {
      // 만료시간전에는 기존 Access Token을 보내자.
      // Cors 때문에 일반 fetch 못쓰고 axios 사용함....
      const response = await axios.post(appConfig.app_client_url, reqBody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8;",
          Authorization: "Basic " + basicHeader,
        },
      });

      if (response.status === 200) {
        setAccessToken(response.data.access_token, dayjs().add(response.data.expires_in - 3, "s"));
      } else {
        return null;
      }
    }
    return appConfig.access_token;
  }
}
