import { useState, forwardRef, useImperativeHandle, useCallback, useEffect, useRef } from "react";
import { Box, Button, CardMedia, Divider, Drawer, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { HttpGameApi } from "../../../interface/game-rest-api";
import { useWindowSize } from "../../../Utils/useWindowSize";

import GameRow from "./GameRow";
import GameDetail from "./GameDetail";

import "./LiveGameListPc.css";

interface propsType {
  userId: string;
  custNo: string;
  nickName: string;
  gameList: any;
  callback: any;
}

const gameApi = new HttpGameApi();

const LiveGameListPc = (props: propsType, ref: any) => {
  const windowSize = useWindowSize();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedGame, setSelectedGame] = useState<any>(null);

  const [checkFinish, setCheckFinish] = useState(false);
  const [alreadyJoin, setAlreadyJoin] = useState(true);

  useEffect(() => {
    return () => {
      setOpenDrawer(false);
      setSelectedGame(null);

      setCheckFinish(false);
      setAlreadyJoin(true);
    };
  }, []);

  const open = useCallback(() => {
    setOpenDrawer(false);
    setSelectedGame(null);
    setCheckFinish(false);
    setAlreadyJoin(true);
    setOpenDrawer(true);
  }, []);

  const close = () => {
    setOpenDrawer(false);
  };

  // 이미 게임에 참여했는지 확인
  const checkGameState = async () => {
    setCheckFinish(false);
    const param: any = {
      game_info: selectedGame,
      user_id: props.userId,
    };

    const res = await gameApi.check_game(param);
    console.log("res : ", res);
    if (res.response.result === "exist") setAlreadyJoin(true);
    else setAlreadyJoin(false);
    setCheckFinish(true);
  };

  useEffect(() => {
    if (selectedGame) checkGameState();
  }, [selectedGame]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  const [videoWidth, setVideoWidth] = useState(364);
  const [videoHeigth, setVideoHeigth] = useState(364);
  const [divTop, setDivTop] = useState(0);
  const [divHeight, setDivHeight] = useState(0);
  useEffect(() => {
    const div = document.getElementById("tab-screen-content");
    const icon = document.getElementById("game-button");
    if (div && icon) {
      console.log("div?.getBoundingClientRect().height : ", div?.getBoundingClientRect().height);
      console.log("div : ", div?.getBoundingClientRect());
      console.log("icon : ", icon?.getBoundingClientRect());
      setVideoWidth(div?.getBoundingClientRect().width);
      setVideoHeigth(div?.getBoundingClientRect().height);
      const topDiv = div?.getBoundingClientRect().top - icon?.getBoundingClientRect().top;
      const heightDiv = div?.getBoundingClientRect().height - (icon?.getBoundingClientRect().top - div?.getBoundingClientRect().top);
      setDivTop(topDiv);
      setDivHeight(heightDiv);
    }
  }, [windowSize]);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: divTop,
          left: `0px`,
          width: videoWidth,
          zIndex: "999",
          ...(openDrawer ? { display: "flex" } : { display: "none" }),
        }}
      >
        <Box
          sx={{ position: "relative", width: "100%", height: videoHeigth }}
          onClick={(e) => {
            if (e.target === e.currentTarget || e.target === document.getElementsByClassName("btn-close")[0]) {
              close();
            }
          }}
        >
          <Paper
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              borderRadius: "10px 10px 0px 0px",
            }}
            elevation={0}
          >
            <Box
              sx={{
                width: "100%",
                height: `${divHeight}px`,
                bgcolor: "while",
                borderRadius: "16px",
                position: "relative",
                padding: "0px",
                overflow: "hidden",
              }}
            >
              {!selectedGame ? (
                <Box className="live-game-pc-list-root">
                  <Stack direction={"column"} spacing={0} className="wrap">
                    <Box className="header">
                      <IconButton
                        onClick={() => {
                          close();
                        }}
                        sx={{ p: 0 }}
                      >
                        <CloseIcon sx={{ fontSize: "30px", color: "#BBBBBB" }} />
                      </IconButton>
                    </Box>
                    <Box>라이브 이벤트</Box>
                    <Box sx={{ height: "16px" }}></Box>
                    <Box>
                      <Stack direction={"column"} spacing={2}>
                        {props.gameList.map((game: any, index: number) => (
                          <GameRow
                            key={`live-game-${index}`}
                            userId={props.userId}
                            gameInfo={game}
                            callback={(payload: any) => {
                              if (payload.command === "select_game") setSelectedGame(game);
                            }}
                          />
                        ))}
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              ) : (
                <GameDetail
                  userId={props.userId}
                  custNo={props.custNo}
                  nickName={props.nickName}
                  gameInfo={selectedGame}
                  mode={"pc"}
                  callback={(payload: any) => {
                    if (payload.command === "go_game_list") setSelectedGame(null);
                    else if (payload.command === "apply_game") props.callback(payload);
                    else if (payload.command === "need_login") props.callback(payload);
                  }}
                />
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default forwardRef(LiveGameListPc);
