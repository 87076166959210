import { forwardRef } from "react";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Local CSS
import "./LiveGameDraw.css";
import { Grid, Stack } from "@mui/material";

import { CommonUtils } from "../../../Utils/common_utils";

interface propsType {
  gameWinner: any;
  drawEventCallback: any;
  userId: string;
}

const LiveGameDraw = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();
  const completeDraw = () => {
    props.drawEventCallback();
  };

  return (
    <>
      <div className="live-game-draw-wrapper">
        {props.gameWinner.indexOf(props.userId) > -1 && (
          <div className="pyro">
            <div className="before"></div>
            <div className="after"></div>
          </div>
        )}
        <Stack className="live-game-draw-stack">
          {props.gameWinner.indexOf(props.userId) > -1 && (
            <Box component="img" className="live-game-draw-img" src="/images/game-draw.png" />
          )}
          <Typography className="live-game-draw-text" fontFamily="Noto Sans KR" fontWeight="700" fontSize="21px" fontStyle="normal">
            {props.gameWinner.indexOf(props.userId) > -1 ? "축하합니다! 당첨되셨습니다!" : "아쉽지만 당첨되지 않았습니다."}
          </Typography>
          <Box className="live-game-draw-apply-box">
            <Grid container spacing={6}>
              {props.gameWinner.length > 0 &&
                props.gameWinner.map((winner: string, index: number) => {
                  return (
                    <Grid key={`${winner}#${index}`} item xs={4}>
                      <Typography
                        className="live-game-draw-text"
                        fontFamily="Noto Sans KR"
                        fontWeight="700"
                        fontSize="14px"
                        fontStyle="normal"
                        color="#F2FF5A"
                      >
                        {cUtils.maskingChar(winner.split("@")[0])}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <Button
            sx={{
              color: "#fff",
              border: "1px solid #fff",
              borderRadius: "6px",
              height: "40px",
              overflow: "hidden",
              marginTop: "10px",
              "&:hover": { border: "1px solid #fff" },
            }}
            variant="outlined"
            onClick={() => {
              completeDraw();
            }}
          >
            <Typography className="live-game-draw-text" fontFamily="Noto Sans KR" fontWeight="700" fontSize="14px" fontStyle="normal">
              확인
            </Typography>
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default forwardRef(LiveGameDraw);
