import { useEffect, forwardRef, useRef } from "react";
import Marquee from "react-easy-marquee";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LiveSubModulesApp from "../subModule/LiveSubModulesApp";

// Local CSS
import "./LivePinMsg.css";

interface propsType {
  msg: string;
  broad_info: any;
  isNotFloat: any;
  isFloat: any;
}

const LivePinMsgApp = (props: propsType, ref: any) => {
  const liveSubModuleRef: any = useRef();

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
    };
  }, []);

  const handleNoticeDrawerOpen = () => {
    liveSubModuleRef.current.open("pin");
  };

  return (
    <>
      {props.msg.length > 0 ? (
        <div className={`live-pin-msg-wrapper`}>
          <Box className={`live-pin-msg-root`} onClick={handleNoticeDrawerOpen}>
            <Box className="live-pin-msg-item">
              <Box className="live-pin-msg-text" sx={{ width: "100%" }}>
                <Marquee duration={20000} reverse={true}>
                  <div>
                    <Typography className="live-pin-msg-marquee-content" sx={{ mr: 5 }}>
                      {props.msg}
                    </Typography>
                  </div>
                </Marquee>
              </Box>
            </Box>
          </Box>
          {/* 상세보기 */}
          <LiveSubModulesApp ref={liveSubModuleRef} broad_info={props.broad_info} isNotFloat={props.isNotFloat} isFloat={props.isFloat} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(LivePinMsgApp);
