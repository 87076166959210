import { useState, useCallback, useEffect } from "react";
import { Box, CardMedia, IconButton, Stack, Typography } from "@mui/material";
import { HttpGameApi } from "../../../interface/game-rest-api";

interface propsType {
  userId: string;
  gameInfo: any;
  callback: any;
}

const gameApi = new HttpGameApi();

const GameRow = (props: propsType) => {
  const [checkFinish, setCheckFinish] = useState(false);
  const [alreadyJoin, setAlreadyJoin] = useState(false);

  useEffect(() => {
    return () => {
      setCheckFinish(false);
      setAlreadyJoin(false);
    };
  }, []);

  const checkGameState = useCallback(async () => {
    setCheckFinish(false);
    const param: any = {
      game_info: props.gameInfo,
      user_id: props.userId,
    };

    const res = await gameApi.check_game(param);
    if (res.response.result === "exist") setAlreadyJoin(true);
    else setAlreadyJoin(false);
    setCheckFinish(true);
  }, [props.gameInfo, props.userId]);

  useEffect(() => {
    // 이미 게임에 참여했는지 확인
    if (props.gameInfo) {
      if (props.userId) checkGameState();
      else {
        setCheckFinish(true);
      }
    }
  }, [props.gameInfo, props.userId]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "8px", alignItems: "center" }}>
        <CardMedia
          component={"img"}
          image={props.gameInfo.event_condition.gift_image_url}
          sx={{ height: "60px", width: "60px", borderRadius: "50%", objectFit: "cover" }}
        />
        <Box sx={{ width: "100%" }}>
          <Stack direction={"column"} spacing={"2px"}>
            <Typography
              sx={{ fontSize: "12px", lineHeight: "14px", color: "#ff0000" }}
            >{`${props.gameInfo.event_condition.draw_number}명 추첨`}</Typography>
            <Typography>{`${props.gameInfo.event_condition.title}`}</Typography>
            <Typography sx={{ fontSize: "12px", lineHeight: "14px" }}>{`${props.gameInfo.event_condition.gift_title}`}</Typography>
          </Stack>
        </Box>
        {checkFinish && (
          <Box>
            <Box
              sx={{
                backgroundColor: "#000000",
                borderRadius: "20px",
                width: "84px",
                padding: 1,
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                props.callback({ command: "select_game", game: props.gameInfo });
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#ffffff" }}>
                {props.gameInfo.game_status === "CREATE"
                  ? "진행예정"
                  : props.gameInfo.game_status === "START"
                  ? alreadyJoin
                    ? "참여완료"
                    : "진행중"
                  : "종료"}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GameRow;
