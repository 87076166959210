import { useEffect, useRef, forwardRef } from "react";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LiveSubModulesPC from "../subModule/LiveSubModulesPC";

// Local CSS
import "./LiveNotice.css";

interface propsType {
  broad_info: any;
  isNotFloat: any;
  isFloat: any;
  hidden_menu: boolean;
}

const LiveNotice = (props: propsType, ref: any) => {
  const liveSubModuleRef: any = useRef();

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
    };
  }, []);

  const handleNoticeDrawerOpen = () => {
    liveSubModuleRef.current.open("notice");
  };

  useEffect(() => {
    // if (props.hidden_menu) handleNoticeDrawerClose();
  }, [props.hidden_menu]);

  return (
    <>
      {props.broad_info.broad_notice.length > 0 ? (
        <>
          <div className={`live-notice-wrapper`}>
            <Box className={`live-notice-root`} onClick={handleNoticeDrawerOpen}>
              <Box className="live-notice-item">
                <Box component="img" className="live-notice-img" src="/images/notice.png" />
                <span>&nbsp;</span>
                <Typography
                  className="live-notice-text"
                  fontFamily="Noto Sans KR"
                  fontWeight="700"
                  fontSize="14px"
                  fontStyle="normal"
                  color="#FFFFFF"
                >
                  {"라이브 혜택"}
                </Typography>
              </Box>
            </Box>
          </div>
          <LiveSubModulesPC
            ref={liveSubModuleRef}
            broad_info={props.broad_info}
            isFloat={props.isFloat}
            isNotFloat={props.isNotFloat}
            hidden_menu={props.hidden_menu}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(LiveNotice);
