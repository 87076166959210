export const appConfig = {
  listChannelMode: "recent" /*recent or total*/,
  app_client_id: "72k5qs34dj0uqkoodp35qc1qvg",
  app_client_secret: "1iglhjfpqv8a5rsmuo89r13ujksubbd3b7ah3vs6u9booffq6rdr",
  app_client_url: "https://api-livartlivedev-livestream-hd.auth.ap-northeast-2.amazoncognito.com/oauth2/token",
  access_token: undefined,
  access_token_expire_time: undefined,
  quote_separator: "⤷",
  vod_cloudfront_endpoint: "https://livart-vod.livehyundai.com",
  iotRegion: "ap-northeast-2",
  iotIdentityPollId: "ap-northeast-2:163a3372-aa3c-4101-bb6e-b6c5fd547382",
  iotEndPoint: "a24the5wvo6x1d-ats.iot.ap-northeast-2.amazonaws.com",
  domain: "https://livart-live.livehyundai.com",
  chat_id_mask: false,
  kakao_app_key: "1cfb94b8e1ffaef097e945f5a27ec0fe",
  address_livart_stg_api: "https://cwww.hyundailivart.co.kr/api/live",
  address_livart_api: "https://www.hyundailivart.co.kr/api/live",
  current_livart_api: "https://www.hyundailivart.co.kr/api/live",
  recent_enter_private_channel: "recent_enter_private_channel",
  address_livart_banword_s3_url: "https://livart-asset.livehyundai.com/banwordlist.txt",
};

export const setLivartAPI_Product = () => {
  appConfig.current_livart_api = appConfig.address_livart_api;
};

export const setLivartAPI_Stg = () => {
  appConfig.current_livart_api = appConfig.address_livart_stg_api;
};

export const setAccessToken = (token: any, time: any) => {
  appConfig.access_token = token;
  appConfig.access_token_expire_time = time;
};
