import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ListItem, ListItemText, Paper, Stack, Tab, Tabs } from "@mui/material";

import { HttpChatApi } from "../../../interface/chat-rest-api";

// Local CSS
import "./LiveSubModules.css";
import dayjs from "dayjs";

interface propsType {
  broad_info: any;
  isNotFloat: any;
  isFloat: any;
  hidden_menu: boolean;
}

const chatApi = new HttpChatApi();

const LiveSubModulesPC = (props: propsType, ref: any) => {
  const [openDrawer, setOpenDrawer] = useState(false); // 공지사항, Q&A 상세보기
  const [tabName, setTabName] = useState("notice");
  const [pinMessages, setPinMessages] = useState<any>([]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setTabName("notice");
      setOpenDrawer(false);
    };
  }, []);

  // 상세보기 Drawer Control
  const closeDrawer = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      props.isFloat();
    }, 200);
  };

  const open = (_tabName: string) => {
    if (_tabName === "pin") {
      get_pin_messages();
    }
    setTabName(_tabName);
    props.isNotFloat();

    setTimeout(() => {
      setOpenDrawer(true);
    }, 200);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "pin") {
      get_pin_messages();
    }
    setTabName(newValue);
  };

  const get_pin_messages = async () => {
    const param: any = {
      command: "get_pin_messages",
      broad_seq: props.broad_info.broad_seq,
      sort: "DESCEND",
    };

    const res = await chatApi.post(param);
    if (res.code === "200") {
      setPinMessages(res.response.pin_messages);
    }
  };

  const AutoLink = (prop: any) => {
    const delimiter = /(https?:\/\/[^\s]+)/g;
    return (
      <>
        {prop.text.split(delimiter).map((word: any, index: number) => {
          const match = word.match(delimiter);
          if (match) {
            const url = match[0];
            return (
              <a
                key={`a-t-k-${index}`}
                style={{ textDecoration: "underline", color: "#FF376D" }}
                target="_blank"
                href={url.startsWith("http") ? url : `http://${url}`}
                rel="noreferrer"
              >
                {url}
              </a>
            );
          }
          return word;
        })}
      </>
    );
  };

  useEffect(() => {
    if (props.hidden_menu) closeDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hidden_menu]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        zIndex: "999",
        bottom: "0px",
        ...(openDrawer ? { display: "flex" } : { display: "none" }),
      }}
    >
      <Box
        sx={{ position: "relative", width: "100%", height: "100vh" }}
        onClick={(e) => {
          if (e.target === e.currentTarget || e.target === document.getElementsByClassName("btn-close")[0]) {
            closeDrawer();
          }
        }}
      >
        <Paper
          sx={{ position: "absolute", bottom: "0px", display: "flex", width: "100%", borderRadius: "10px 10px 0px 0px" }}
          elevation={0}
        >
          <Box
            sx={{
              width: "100%",
              height: "60vh",
              bgcolor: "while",
              borderRadius: "16px",
              position: "relative",
              padding: "18px",
              overflow: "hidden",
            }}
          >
            {/* 공지사항 상세보기 상단 */}
            <Box className="sub-module-drawer-top">
              <Box component="img" src="/images/modal-notice.png" className="sub-module-drawer-top-img" />
              <div className="sub-module-drawer-top-text">&nbsp;라이브 안내사항</div>
              <Box className="sub-module-drawer-close">
                <Box
                  className="btn-close"
                  component="img"
                  src="/images/notice-close.svg"
                  sx={{ cursor: "pointer" }}
                  onClick={closeDrawer}
                />
              </Box>
            </Box>
            {/* 탭 영역 */}
            <Tabs
              value={tabName}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { backgroundColor: "#000000" },
              }}
              sx={{
                ".Mui-selected": {
                  color: `#000000 !important`,
                },
              }}
            >
              <Tab value="pin" label="라이브 공지사항" sx={{ fontFamily: "Noto Sans KR" }} />
              <Tab value="notice" label="라이브 혜택" sx={{ fontFamily: "Noto Sans KR" }} />
            </Tabs>
            <Box sx={{ height: "10px" }}></Box>
            <Box className="sub-module-content">
              {/* 라이브 혜택 */}
              {tabName === "notice" && (
                <Box>
                  <Box sx={{ width: "100%", height: "100%", whiteSpace: "pre-line" }}>
                    <Typography component="div">
                      <AutoLink text={props.broad_info.broad_notice} />
                    </Typography>
                  </Box>
                </Box>
              )}
              {tabName === "pin" && (
                <Box>
                  <Stack direction={"column"} spacing={2}>
                    {pinMessages.map(
                      (msg: any, index: number) =>
                        msg.message !== "" && (
                          <Box key={`pin-message-${index}`}>
                            <ListItem disablePadding sx={{ alignItems: "start" }}>
                              {/* Question Icon */}
                              <ListItemText
                                className="sub-module-question-item"
                                primary={
                                  <Typography
                                    paddingRight="10px"
                                    component="div"
                                    fontFamily="Noto Sans KR"
                                    fontWeight="700"
                                    fontSize="16px"
                                    fontStyle="normal"
                                    color="#111111"
                                    sx={{ wordBreak: "break-all" }}
                                  >
                                    <AutoLink text={msg.message} />
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    component="div"
                                    fontFamily="Noto Sans KR"
                                    fontWeight="400"
                                    fontSize="13px"
                                    fontStyle="normal"
                                    color="#999999"
                                  >
                                    {dayjs(String(msg.sk)).format("YYYY.MM.DD, HH:mm")}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        )
                    )}
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default forwardRef(LiveSubModulesPC);
