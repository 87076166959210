import { useEffect, useRef, useState } from "react";
import { isMobile, browserName, osName } from "react-device-detect";
import { IconButton, Typography } from "@mui/material";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import MicIcon from "@mui/icons-material/Mic";
import LiveGameListPc from "./LiveGameListPc";

interface propsType {
  userId: string;
  custNo: string;
  nickName: string;
  gameList: any;
  callback: any;
}

const LiveGamePc = (props: propsType, ref: any) => {
  const gameListRef: any = useRef();
  return (
    <>
      <IconButton
        onClick={() => {
          props.callback({ command: "get_game_list" });
          gameListRef.current.open();
        }}
        sx={{ ml: "10px" }}
        id="game-button"
      >
        <SportsEsportsIcon sx={{ color: "#ffffff" }} />
      </IconButton>
      <LiveGameListPc
        ref={gameListRef}
        userId={props.userId}
        custNo={props.custNo}
        nickName={props.nickName}
        gameList={props.gameList}
        callback={(payload: any) => {
          if (payload.command === "apply_game") {
            props.callback(payload);
          } else if (payload.command === "need_login") {
            props.callback(payload);
          }
        }}
      />
    </>
  );
};

export default LiveGamePc;
