import { useState, useCallback, useEffect, useRef } from "react";
import dayjs from "dayjs";

import { Box, Button, CardMedia, Stack, TextField, Typography } from "@mui/material";

import { HttpGameApi } from "../../../interface/game-rest-api";

import ModalWinnerList from "./ModalWinnerList";
import "./GameDetail.css";
import "./GameDetailPc.css";

interface propsType {
  userId: string;
  custNo: string;
  nickName: string;
  gameInfo: any;
  mode: string;
  callback: any;
}

const gameApi = new HttpGameApi();

const GameDetail = (props: propsType) => {
  const winnerRef: any = useRef();
  const orderNumRef: any = useRef();

  const [checkFinish, setCheckFinish] = useState(false);
  const [alreadyJoin, setAlreadyJoin] = useState(false);
  const [orderNum, setOrderNum] = useState("");

  useEffect(() => {
    setOrderNum("");
    return () => {
      setCheckFinish(false);
      setAlreadyJoin(false);
      setOrderNum("");
    };
  }, []);

  const checkGameState = useCallback(async () => {
    setCheckFinish(false);
    const param: any = {
      game_info: props.gameInfo,
      user_id: props.userId,
    };

    const res = await gameApi.check_game(param);
    if (res.response.result === "exist") setAlreadyJoin(true);
    else setAlreadyJoin(false);
    setCheckFinish(true);
  }, [props.gameInfo, props.userId]);

  useEffect(() => {
    // 이미 게임에 참여했는지 확인
    console.log("props.userId : ", props.userId);
    if (props.gameInfo) {
      if (props.userId) checkGameState();
      else {
        setCheckFinish(true);
      }
    }
  }, [props.gameInfo, props.userId]);

  const applyGame = async () => {
    if (alreadyJoin) return;
    if (props.userId === "" || props.userId === undefined) {
      console.log("로그인 필요");
      props.callback({ command: "need_login" });
      return;
    }

    if (props.gameInfo.flag === "authBuy") {
      if (orderNum === "") {
        orderNumRef.current.focus();
        return;
      }
    }
    const param: any = {
      game_info: props.gameInfo,
      user_id: props.userId,
      cust_no: props.custNo,
      nick_name: props.nickName,
      apply_info: {
        order_number: orderNum,
      },
    };

    const res = await gameApi.apply_game(param);
    if (res.code === "200") {
      if (res.response.result === "success") {
        props.callback({ command: "apply_game" });
        checkGameState();
      }
    }
  };

  // 당첨자 명단 확인
  const viewWinnerList = () => {
    winnerRef.current.open(props.gameInfo.draw_result);
  };

  return (
    <Box className={props.mode === "mo" ? "live-game-detail-root noScrollBar" : "live-game-pc-detail-root noScrollBar"}>
      <Stack direction={"column"} spacing={0} className="wrap">
        <Box
          onClick={() => {
            props.callback({ command: "go_game_list" });
          }}
        >
          {"< 이벤트목록"}
        </Box>
        <Box sx={{ height: "16px" }}></Box>
        <Box>
          <Stack direction={"column"} spacing={2}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography>{props.gameInfo.event_condition.title}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CardMedia
                component={"img"}
                image={props.gameInfo.event_condition.gift_image_url}
                sx={{ width: "192px", height: "192px", borderRadius: "50%", objectFit: "cover" }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ color: "#ff0000" }}>{`${props.gameInfo.event_condition.draw_number}명`}</Typography>
              <Typography>{`을 추첨하여 경품을 드립니다.`}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography>{props.gameInfo.event_condition.gift_title}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", whiteSpace: "pre-line" }}>
              <Typography>{props.gameInfo.event_condition.notice}</Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Box>
        {props.gameInfo.game_status === "START" && props.gameInfo.flag === "authBuy" && !alreadyJoin && checkFinish && props.userId && (
          <Stack direction="column" spacing={2}>
            <TextField
              inputRef={orderNumRef}
              value={orderNum}
              placeholder="주문번호를 입력하세요."
              variant="outlined"
              fullWidth
              required
              type="search"
              inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
              onChange={(e) => {
                setOrderNum(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") applyGame();
              }}
            />
            <Button variant="contained" fullWidth onClick={applyGame}>
              참여
            </Button>
          </Stack>
        )}
        {props.gameInfo.game_status === "START" && !alreadyJoin && checkFinish && !props.userId && (
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              console.log("123");
              props.callback({ command: "need_login" });
            }}
          >
            로그인
          </Button>
        )}
        {props.gameInfo.game_status === "START" && alreadyJoin && checkFinish && (
          <Button variant="contained" fullWidth disabled>
            참여완료
          </Button>
        )}
        {props.gameInfo.game_status === "CREATE" && (
          <Button variant="contained" fullWidth disabled>
            진행예정
          </Button>
        )}
        {props.gameInfo.game_status === "END" && (
          <Stack direction={"column"} spacing={2}>
            {dayjs(props.gameInfo.start_dtm).add(1, "day") > dayjs() && (
              // 당첨자는 1일동안만 보임
              <Button variant="contained" fullWidth onClick={viewWinnerList}>
                당첨자 명단 확인
              </Button>
            )}

            <Button variant="contained" fullWidth disabled>
              종료
            </Button>
          </Stack>
        )}
      </Box>
      <ModalWinnerList ref={winnerRef} />
    </Box>
  );
};

export default GameDetail;
