import { Typography } from "@mui/material";
import "./StreamErrorNotice.css";
const StreamErrorNotice = () => {
  return (
    <>
      <Typography className="error-notice"> {"방송이 안보이시나요?\n새로고침 하시거나\n현대리바트 APP으로\n이용해주세요."}</Typography>
    </>
  );
};

export default StreamErrorNotice;
