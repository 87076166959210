import { useEffect, forwardRef, useRef } from "react";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LiveSubModulesApp from "../subModule/LiveSubModulesApp";

// Local CSS
import "./LiveNotice.css";

interface propsType {
  broad_info: any;
  isNotFloat: any;
  isFloat: any;
}

const LiveNoticeApp = (props: propsType, ref: any) => {
  const liveSubModuleRef: any = useRef();

  useEffect(() => {
    return () => {};
  }, []);

  const handleDrawerOpen = () => {
    liveSubModuleRef.current.open("notice");
  };

  return (
    <>
      {props.broad_info.broad_notice.length > 0 ? (
        <div className={`live-notice-wrapper`}>
          <Box className={`live-notice-root`} onClick={handleDrawerOpen}>
            <Box className="live-notice-item">
              <Box component="img" className="live-notice-img" src="/images/notice.png" />
              <span>&nbsp;</span>
              <Typography
                className="live-notice-text"
                fontFamily="Noto Sans KR"
                fontWeight="700"
                fontSize="14px"
                fontStyle="normal"
                color="#FFFFFF"
              >
                {"라이브 혜택"}
              </Typography>
            </Box>
          </Box>
          {/* 상세보기 */}
          <LiveSubModulesApp ref={liveSubModuleRef} broad_info={props.broad_info} isNotFloat={props.isNotFloat} isFloat={props.isFloat} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(LiveNoticeApp);
