import { ApiBase, BaseParams } from "./api-base";

export class HttpChatApi extends ApiBase {
  onApiName(): string {
    return "ChatApiName";
  }

  onUrlPath(): string {
    return "/chat";
  }

  public async list_chat_history(param: any) {
    let requestBody: any = param;
    requestBody.command = "list_chat_history";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async list_chat_limit_history(param: any) {
    let requestBody: any = param;
    requestBody.command = "list_chat_limit_history";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_block_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_block_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async post(param: any) {
    const response = this.requestPost(param);
    return response;
  }
}
