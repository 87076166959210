import { useEffect, useRef, forwardRef } from "react";
import Marquee from "react-easy-marquee";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LiveSubModulesPC from "../subModule/LiveSubModulesPC";

// Local CSS
import "./LivePinMsg.css";

interface propsType {
  msg: string;
  broad_info: any;
  isNotFloat: any;
  isFloat: any;
  hidden_menu: boolean;
}

const LiveNoticePC = (props: propsType, ref: any) => {
  const liveSubModuleRef: any = useRef();

  useEffect(() => {
    return () => {};
  }, []);

  const handleNoticeDrawerOpen = () => {
    liveSubModuleRef.current.open("pin");
  };

  return (
    <>
      {props.msg.length > 0 ? (
        <>
          <div className={`live-pin-msg-wrapper`}>
            <Box className={`live-pin-msg-root`} onClick={handleNoticeDrawerOpen}>
              <Box className="live-pin-msg-item">
                <Box className="live-pin-msg-text" sx={{ width: "100%" }}>
                  <Marquee duration={20000} reverse={true}>
                    <div>
                      <Typography className="live-pin-msg-marquee-content" sx={{ mr: 5 }}>
                        {props.msg}
                      </Typography>
                    </div>
                  </Marquee>
                </Box>
              </Box>
            </Box>
          </div>
          <LiveSubModulesPC
            ref={liveSubModuleRef}
            broad_info={props.broad_info}
            isFloat={props.isFloat}
            isNotFloat={props.isNotFloat}
            hidden_menu={props.hidden_menu}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(LiveNoticePC);
