import { useEffect, useState } from "react";
import { CommonUtils } from "../../../Utils/common_utils";
import "./BroadWait.css";

interface propsType {
  remainTime: string;
  broad_info: any;
}

const BroadWaitIOS = (props: propsType) => {
  const cUtils = new CommonUtils();

  const [broadStartText, setBroadStartText] = useState("");

  useEffect(() => {
    setBroadStartText(cUtils.convertBroadStartText(props.broad_info.broad_start_tm));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.broad_info.broad_start_tm]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBroadStartText("");
    };
  }, []);

  return (
    <div>
      <div className="broad-wait-remain-item snow">
        <div className="broad-wait-remain-live-mark">
          <img src="/images/comingsoon.png" alt="" />
        </div>
        <div className="broad-wait-remain-comming-soon">COMING SOON</div>
        <div className="broad-wait-remain-broad-time">{broadStartText}</div>
        {props.remainTime !== "" && props.remainTime !== undefined && <div className="broad-wait-remain-time">{props.remainTime}</div>}
      </div>
    </div>
  );
};

export default BroadWaitIOS;
