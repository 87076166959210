import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IconButton, Stack, Tooltip } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { Trie, Emit } from "@tanishiking/aho-corasick";
import { appConfig } from "../../../../config/Config";

interface propsType {
  broad_status: string;
  user_id: string;
  nickName: string;
  livart_id: string;
  sendMessage: any;
  pcMode: boolean;
  callback: any;
}

interface chatSendForm {
  msg: string;
}

const LiveChatTF = (props: propsType, ref: any) => {
  const inputChatRef: any = useRef();
  const [chatBanWordList, setChatBanWordList] = useState<any>([]);

  const [inputChatTFDisplay, setInputChatTFDisplay] = useState("none");

  const [nickName, setNickName] = useState("");
  const [mode, setMode] = useState("change_nickname");
  useEffect(() => {
    getBanWord();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setInputChatTFDisplay("none");
      setChatBanWordList([]);
      setNickName("");
      setMode("change_nickname");
    };
  }, []);

  // Form for send message to chime channel
  const {
    register,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFocus,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<chatSendForm>();

  // 금칙어 단어 s3에서 불러오기
  const getBanWord = async () => {
    fetch(appConfig.address_livart_banword_s3_url)
      .then((response) => response.text())
      .then((data) => {
        var banList = data.split("\r\n");
        setChatBanWordList(banList);
      });
  };

  // 금칙어 필터링
  const banFiltering = (chatMsg: string) => {
    const trie = new Trie(chatBanWordList);
    const emits: Emit[] = trie.parseText(chatMsg);
    // console.log(emits)
    if (emits.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // 채팅 메시지 전송버튼 클릭
  const onSubmit: SubmitHandler<chatSendForm> = async (data) => {
    if (banFiltering(data.msg.split(" ").join(""))) {
      alert("사용할 수 없는 단어가 포함되어 있습니다.");
    } else {
      document.getElementById("input-chat-Message")?.focus();
      reset({ msg: "" });
      await props.sendMessage(data.msg, "");
    }
  };

  const handleHiddenForm = (hidden: boolean) => {
    if (hidden) setInputChatTFDisplay("none");
    else {
      setInputChatTFDisplay("flex");
      // 가상의 인풋을 만든다
      const fakeInput: any = document.createElement("input");
      fakeInput.setAttribute("type", "text");
      fakeInput.style.position = "absolute";
      fakeInput.style.opacity = 0;
      fakeInput.style.height = 0;
      fakeInput.style.fontSize = "16px"; // disable auto zoom
      window.document.body.appendChild(fakeInput);
      fakeInput.focus(); // 가상의 인풋에 먼저 포커스를 주고

      setTimeout(() => {
        window.document.getElementById("input-chat-Message")?.focus(); // 실제 우리 채팅창에 포커스(근데 이건 다른 컴포넌트에 있다..)
        window.document.body.removeChild(fakeInput); // 가상의 인풋은 삭제
      }, 300);
    }
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    handleHiddenForm,
  }));

  const chnageNickname = () => {
    if (nickName.split(" ").join("") === "") {
      props.callback({ command: "toast", type: "error", msg: "사용할 닉네임을 입력하세요." });
      return;
    }
    if (banFiltering(nickName.split(" ").join(""))) {
      props.callback({ command: "toast", type: "error", msg: "사용할 수 없는 단어가 포함되어있습니다." });
      return;
    }
    if (props.livart_id !== "" && props.livart_id !== undefined) {
      props.callback({ command: "nickname_change", userId: props.livart_id, nickName: nickName });
    } else {
      window.localStorage.setItem("livartLiveNickName", nickName);
      props.callback({ command: "nickname_changed" });
    }
  };

  useEffect(() => {
    if (props.nickName !== "" && props.nickName !== undefined) {
      setNickName(props.nickName);
      setMode("chat");
    } else {
      setMode("change_nickname");
    }
  }, [props.nickName]);

  return (
    <>
      {/* 채팅입력 */}
      {mode === "chat" && (
        <Box
          id="input-chat"
          sx={
            props.broad_status === "VOD"
              ? { flexGrow: 1, display: "none" }
              : props.pcMode
              ? { flexGrow: 1, display: "flex", alignItems: "center" }
              : {
                  flexGrow: 1,
                  display: inputChatTFDisplay,
                  alignItems: "center",
                }
          }
          className={props.pcMode ? "chat-input-root-pc" : "chat-input-root"}
          ref={inputChatRef}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Box sx={{ width: "100%" }}>
            <Stack direction="row" spacing={0} sx={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Tooltip title="닉네임 변경">
                <Box>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      setMode("change_nickname");
                    }}
                    edge="start"
                    sx={{ ml: "0px" }}
                  >
                    <ManageAccountsIcon sx={{ color: "#35463f" }} />
                  </IconButton>
                </Box>
              </Tooltip>

              <TextField
                id="input-chat-Message"
                className="chat-without-padding "
                inputProps={
                  props.user_id === ""
                    ? { readOnly: true, enterKeyHint: "Send", style: { overflowY: "hidden", padding: "0px" } }
                    : { enterKeyHint: "Send", style: { overflowY: "hidden", padding: "0px" } }
                }
                type="search"
                autoComplete="off"
                sx={{ width: "100%", color: "black", "&::placeholder": { color: "gray" } }}
                placeholder={`${
                  props.nickName !== "" && props.nickName !== undefined ? "(" + props.nickName + ") " : ""
                }채팅에 참여해 주세요.`}
                {...register("msg", { required: true, maxLength: 3000 })}
              />
              <Button id="input-chat-button" type="submit" variant="text">
                보내기
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
      {mode === "change_nickname" && (
        // 닉네임변경
        <Box
          id="input-chat"
          sx={
            props.broad_status === "VOD"
              ? { flexGrow: 1, display: "none" }
              : props.pcMode
              ? { flexGrow: 1, display: "flex", alignItems: "center" }
              : {
                  flexGrow: 1,
                  display: inputChatTFDisplay,
                  alignItems: "center",
                }
          }
          className={props.pcMode ? "chat-input-root-pc" : "chat-input-root"}
        >
          <TextField
            id="input-chat-Message"
            className="chat-without-padding "
            inputProps={
              props.user_id === ""
                ? { readOnly: true, maxLength: 10, enterKeyHint: "Send", style: { overflowY: "hidden", padding: "0px 14px" } }
                : { maxLength: 10, enterKeyHint: "Send", style: { overflowY: "hidden", padding: "0px 14px" } }
            }
            type="search"
            autoComplete="off"
            sx={{ width: "100%", color: "black", "&::placeholder": { color: "gray" } }}
            placeholder={"사용할 닉네임을 입력하세요(최대10자)"}
            value={nickName}
            onChange={(e) => {
              setNickName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                chnageNickname();
              }
            }}
          />
          <Stack direction={"row"} spacing={1}>
            <Button
              variant="contained"
              size="small"
              sx={{ fontSize: "12px", lineHeight: "18px", minWidth: "50px" }}
              onClick={chnageNickname}
            >
              변경
            </Button>
            {props.nickName !== "" && props.nickName !== undefined && (
              <Button
                variant="outlined"
                size="small"
                sx={{ fontSize: "12px", lineHeight: "18px", minWidth: "50px" }}
                onClick={() => {
                  setMode("chat");
                }}
              >
                취소
              </Button>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default forwardRef(LiveChatTF);
