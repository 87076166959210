import { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import queryString from "query-string";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// login
import AskLoginDialog from "./login/AskLoginDialog";

// chat
import ChatVerNoSdk from "./chat/ChatVerNoSdk";
import ChatVerVod from "./chat/ChatVerVod";
import IoTClient from "../../Utils/IoTClient";

// ivs
import { LiveIVSPlayer, player as livePlayer } from "./ivsPlayer/LiveIVSPlayer";
import { ClientIVSPlayer, player as rehearsalPlayer } from "./ivsPlayer/ClientIVSPlayer";
import { VodIVSPlayer, player as vodPlayer } from "./ivsPlayer/VodIVSPlayer";
import { VodIVSPlayerHigh, player as vodPlayerHigh } from "./ivsPlayer/VodIVSPlayerHigh";
import BroadWait from "./broadWait/BroadWait";
import BroadStop from "./broadStop/BroadStop";

// live menu
import LiveHeader from "./header/LiveHeader";
import LiveFooter from "./footer/LiveFooter";
import LiveNotice from "./notice/LiveNotice";
import LiveNoticePC from "./notice/LiveNoticePC";
import LiveChatTF from "./chat/LiveChatTF";
import LiveGameDraw from "./game/LiveGameDraw";
import ModalAskPassword from "./password/ModalAskPassword";
import LivePinMsg from "./pinMsg/LivePinMsg";
import LivePinMsgPc from "./pinMsg/LivePinMsgPc";
import VodNotice from "./notice/VodNotice";
import VodNoticePC from "./notice/VodNoticePC";
import LiveGame from "./game/LiveGame";
import LiveGamePc from "./game/LiveGamePc";

import StreamErrorNotice from "./notice/StreamErrorNotice";

// api
import { HttpStatisticApi } from "../../interface/statistic-rest-api";
import { HttpGameApi } from "../../interface/game-rest-api";
import { HttpLiveChannelApi } from "../../interface/live-channel-rest-api";
import { HttpChatApi } from "../../interface/chat-rest-api";
import { userState, deviceInfo } from "../../interface/MainInterface";

// common
import { appConfig } from "../../../config/Config";
import { CommonUtils } from "../../Utils/common_utils";

import { useInterval } from "../../Utils/UseInterval";
import Toast2 from "../../Utils/Toast2";

import { isBrowser, isMobile, browserName, fullBrowserVersion, mobileModel, osVersion, osName } from "react-device-detect";

import "./LivePlayer.css";
import { Typography } from "@mui/material";
import PrdCpnModules from "./prdCpnModule/PrdCpnModules";
import PrdCpnModulesPC from "./prdCpnModule/PrdCpnModulesPC";
import LiveCoupon from "./coupon/LiveCoupon";
import LiveProduct from "./product/LiveProduct";

interface propsType {
  webViewStatus: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const livartTestList: any = [
  {
    pageNum: 1,
    pageNo: 1,
    blockSize: 10,
    pageSize: 10,
    rowCount: 3,
    pageCount: 1,
    sortBy: null,
    sortCol: "",
    sortKind: "",
    excelDnldRson: null,
    excelDnlUrl: null,
    excelDnlName: null,
    searchParams: null,
    regrNm: null,
    modrNm: null,
    grantSiteSns: null,
    grantCorpSn: null,
    grantCorpErpId: null,
    grantShopSn: null,
    grantShopErpId: null,
    brodCstSn: "BC00000002",
    goodsSn: "P100024396",
    rgstDtime: null,
    regrSn: null,
    mdfyDtime: null,
    modrSn: null,
    goodsNm: "뉴 레가토 4인 리클라이너 소파 양면형 3색택1",
    salePrc: 1193000,
    goodsStCd: "01",
    goodsImgPath: "https://cstatic.hyundailivart.co.kr/UserFiles/data/image/detail/PRO144/PRO14499/PRO14499_00000000_detail1_ORIGIN.jpg",
    mbrSn: null,
    startRowNum: 1,
    endRowNum: 10,
  },
  {
    pageNum: 1,
    pageNo: 1,
    blockSize: 10,
    pageSize: 10,
    rowCount: 3,
    pageCount: 1,
    sortBy: null,
    sortCol: "",
    sortKind: "",
    excelDnldRson: null,
    excelDnlUrl: null,
    excelDnlName: null,
    searchParams: null,
    regrNm: null,
    modrNm: null,
    grantSiteSns: null,
    grantCorpSn: null,
    grantCorpErpId: null,
    grantShopSn: null,
    grantShopErpId: null,
    brodCstSn: "BC00000002",
    goodsSn: "P100030296",
    rgstDtime: null,
    regrSn: null,
    mdfyDtime: null,
    modrSn: null,
    goodsNm: "비토 4인 세라믹 식탁세트(토핑체어4, 2색/택1)",
    salePrc: 695000,
    goodsStCd: "01",
    goodsImgPath: "https://cstatic.hyundailivart.co.kr/UserFiles/data/image/detail/PRO160/PRO16076/PRO16076_00000000_detail1_ORIGIN.jpg",
    mbrSn: null,
    startRowNum: 1,
    endRowNum: 10,
  },
  {
    pageNum: 1,
    pageNo: 1,
    blockSize: 10,
    pageSize: 10,
    rowCount: 3,
    pageCount: 1,
    sortBy: null,
    sortCol: "",
    sortKind: "",
    excelDnldRson: null,
    excelDnlUrl: null,
    excelDnlName: null,
    searchParams: null,
    regrNm: null,
    modrNm: null,
    grantSiteSns: null,
    grantCorpSn: null,
    grantCorpErpId: null,
    grantShopSn: null,
    grantShopErpId: null,
    brodCstSn: "BC00000002",
    goodsSn: "P100032476",
    rgstDtime: null,
    regrSn: null,
    mdfyDtime: null,
    modrSn: null,
    goodsNm: "뉴 탐 저상형 Q+SS 패밀리침대 쿠션형 양쪽가드(매트별도)",
    salePrc: 884000,
    goodsStCd: "01",
    goodsImgPath: "https://cstatic.hyundailivart.co.kr/UserFiles/data/image/detail/PRO165/PRO16540/PRO16540_00000000_detail1_ORIGIN.jpg",
    mbrSn: null,
    startRowNum: 1,
    endRowNum: 10,
  },
];

let channelParams: any = {
  broad_seq: "",
  broad_title: "",
  broad_desc: "",
  broad_status: "",
  broad_start_tm: "",
  broad_stop_tm: "",
  broad_notice: "",
  broad_prod_list: [],
  broad_cpn_list: [],
  broad_cover_url: "",
  vod_url: "",
  vod_started_at: "",
  vod_start_sec: 0,
  vod_end_sec: 0,
  high_start_sec: 0,
  most_concurrent_view_timestamp: "",
  ivs_channel_arn: "",
  ivs_stream_key: "",
  ivs_ingest_endpoint: "",
  ivs_playback_url: "",
  chime_channel_arn: "",
  chime_admin_arn: "",
  total_likes: 0,
  total_views: 0,
  password: "",
  extra_type: "",
  progress_store: "",
  host_id: "",
  host_name: "",
  host_picture: "",
  channel_img: "",
  channel_img_url: "",
  channel_template: false,
  cnslImgPath: "",
  cnslPageUrl: "",
  bnrImgPath: "",
  bnrPageUrl: "",
};

let lastConcurrentView: number = 0;
let isFirst = true;

const _deviceInfo: deviceInfo = {
  isBrowser: isBrowser,
  isMobile: isMobile,
  browserName: browserName,
  fullBrowserVersion: fullBrowserVersion,
  mobileModel: mobileModel,
  osName: osName,
  osVersion: osVersion,
};

const userStateFormat: userState = {
  id: "",
  nickName: "",
  picture: "",
  isAuth: false,
  isAdmin: false,
  isSuperAdmin: false,
  userArn: "",
};

const liveChannalApi = new HttpLiveChannelApi();
const gameApi = new HttpGameApi();
const statisticApi = new HttpStatisticApi();
const chatApi = new HttpChatApi();

const LivePlayer = (props: propsType) => {
  const screenMode: string = "H";
  const cUtils = new CommonUtils();
  const { broadseq } = useParams(); // URL Parameter 로 방송순번 전달 받음
  // 사용자ID, 채팅용 닉네임, 고객사 로그인 페이지URL을 QueryString으로 전달받음.
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const toastRef: any = useRef();
  const iotRef: any = useRef();
  const liveChatRef: any = useRef();
  const liveChatTFRef: any = useRef();
  const liveHeaderRef: any = useRef();
  const liveFooterRef: any = useRef();
  const liveGameRef: any = useRef();
  const subModuleRef: any = useRef();

  const [endLoadInfo, setEndLoadInfo] = useState(false);
  const [videoStatus, setVideoStatus] = useState("");
  const [hiddenMenu, setHiddenMenu] = useState(false);
  const [chatUrl, setChatUrl] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [controlOpen, setControlOpen] = useState(true);
  const [chatFontColor, setChatFontColor] = useState("rgb(255, 255, 255)");

  const [likeCount, setLikeCount] = useState(0);
  const [totalViewCount, setTotalViewCount] = useState(0);

  const [playerID, setPlayerID] = useState("");
  const [info, setInfo] = useState<any>(channelParams); // 채널정보
  const [broadStatus, setBroadStatus] = useState(""); // 방송의 상태
  const [streamState, setStreamState] = useState(""); // 스트림 상태
  const [videoPosition, setVideoPosition] = useState(""); // 비디오 재생위치
  const [braodWait, setBroadWait] = useState(false); // 예약된 방송인지 확인
  const [remainTime, setRemainTime] = useState(""); // 방송 시작까지 남은시간계산

  const [vodType, setVodType] = useState("full");
  const [haveHighlight, setHaveHighlight] = useState(false);
  const [vodStartSecFull, setVodStartSecFull] = useState(0);
  const [vodEndSecFull, setVodEndSecFull] = useState(0);
  const [vodStartSecHigh, setVodStartSecHigh] = useState(-1);
  const [vodEndSecHigh, setVodEndSecHigh] = useState(-1);
  const [muted, setMuted] = useState(true);

  // 방송 비밀번호 확인
  const [askLogin, setAskLogin] = useState(false);
  const [askPwdModalCommand, setAskPwdModalCommand] = useState("INIT");
  const [passwordConfirm, setPasswordConfirm] = useState(false);

  // 리바트 쿠폰, 상품목록 처리용
  const [coupons, setCoupons] = useState<any>([]);
  const [prodList, setProdList] = useState<any>([]);
  const [isCouponHidden, setIsCouponHidden] = useState(true);

  // 방송 입장알림용
  const [userList, setUserList] = useState<any>([]);
  const [alertUser, setAlertUser] = useState(false);
  const [alertUserText, setAlertUserText] = useState("");

  // 동시시청자 접속돌파 알림
  const [concurrentView, setConcurrentView] = useState(0);
  const [concurrentViewText, setConcurrentViewText] = useState("");
  const [concurrentViewFlag, setConcurrentViewFlag] = useState(false);

  // Q&A
  const [newQnAMsg, setNewQnAMsg] = useState<any>(null);

  // Game Draw
  const [gameList, setGameList] = useState<any>([]);
  const [gameInfo, setGameInfo] = useState<any>({});
  const [gameDraw, setGameDraw] = useState(false);
  const [gameWinner, setGameWinner] = useState<any>([]);
  const [gameSk, setGameSk] = useState("");

  const [videoMode, setVideoMode] = useState(1); // 0: 가로모드 영상, 1: 세로모드 영상

  const [userState, setUserState] = useState<userState>(userStateFormat);

  // 리허설 처리용 (방송 start가 아니라도 비디오 play)
  const [rehearsal, setRehearsal] = useState(false);

  const [ivsInitStart, setIvsInitStart] = useState(false);

  const [noChat, setNoChat] = useState(false);
  const [livartBroadSeq, setLivartBroadSeq] = useState("1");

  const [liveProducts, setLiveProducts] = useState<any>([]);
  const [pinMsg, setPinMsg] = useState("");
  const [pcMode, setPcMode] = useState(false);

  const [iotConnect, setIotConnect] = useState(false);

  // 최초 페이지 로딩
  useEffect(() => {
    // 만약 스테이지 서버 테스트 시 아래 주석 해제, 운영이라면 주석 처리
    // setLivartAPI_Stg();
    checkQueryStrings();
    setPageInit(); // 채널정보 및 채팅방 입장
    return () => {
      setEndLoadInfo(false);
      setVideoStatus("");
      setHiddenMenu(false);
      setChatUrl("");
      setChatOpen(false);
      setControlOpen(true);
      setChatFontColor("rgb(255, 255, 255)");
      setLikeCount(0);
      setTotalViewCount(0);
      setPlayerID("");
      setInfo(channelParams); // 채널정보
      setBroadStatus(""); // 방송의 상태
      setStreamState(""); // 스트림 상태
      setVideoPosition(""); // 비디오 재생위치
      setBroadWait(false); // 예약된 방송인지 확인
      setRemainTime(""); // 방송 시작까지 남은시간계산
      setVodType("full");
      setHaveHighlight(false);
      setVodStartSecFull(0);
      setVodEndSecFull(0);
      setVodStartSecHigh(-1);
      setVodEndSecHigh(-1);
      setMuted(true);
      setAskLogin(false);
      setAskPwdModalCommand("INIT");
      setPasswordConfirm(false);
      setCoupons([]);
      setProdList([]);
      setUserList([]);
      setAlertUser(false);
      setAlertUserText("");
      setConcurrentView(0);
      setConcurrentViewText("");
      setConcurrentViewFlag(false);
      setNewQnAMsg(null);
      setGameInfo({});
      setGameDraw(false);
      setGameWinner([]);
      setGameSk("");
      setVideoMode(1);
      setUserState(userStateFormat);
      setRehearsal(false);
      setIvsInitStart(false);
      setNoChat(false);
      setLiveProducts([]);
      setPinMsg("");
      setLivartBroadSeq("1");
      setPcMode(false);
      setIotConnect(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userState.id !== "" && userState.id !== undefined) putMetaFunc("view"); //// 24.01.04 최초 로딩 시 바로 조회수 업데이트.허정은
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.id]);

  // 쿼리 파라미터 확인
  const checkQueryStrings = () => {
    // console.log("queryParams : ", queryParams);
    if (queryParams.mode !== undefined) {
      if (queryParams.mode === "rehearsal") setRehearsal((rehearsal) => true);
    }
    if (queryParams.livartBroadSeq !== undefined) {
      setLivartBroadSeq(queryParams.livartBroadSeq);
    }
    // 회원이면 쿠폰 리바트로부터 조회
    if(queryParams.custNo && queryParams.custNo !== "") {
      getCouponFromParent();
    }

  };

  const setPageInit = async () => {
    loadChannelInfoFast(); // 채널정보 조회
    procUserJoin(); // 사용자 세션 설정
    loadStatisticInfo(); // 좋아요, 누적시청자 조회
    loadChannelGameList(); // 게임목록 조회
  };

  // 채널정보 조회
  const loadChannelInfoFast = async () => {
    if (broadseq !== undefined) {
      const channelParam: any = {
        broad_seq: broadseq,
      };
      const res = await liveChannalApi.get_channel_info(channelParam);
      const channelInfo = res.response.channel_info;

      checkChannelPwd(channelInfo); // 폐쇄 채널 코드 입력 쿠키 조회
      checkVodStatus(channelInfo); // VOD 시작시간 세팅
      setInfo((info: any) => channelInfo); // 채털정보
      setProdList(channelInfo.broad_prod_list);
      // 비회원이면 쿠폰 정보 IT&E DB에서 조회
      if(!queryParams.custNo || queryParams.custNo === "") {
        if(channelInfo.broad_cpn_list) {
          // 쿠폰 다운로드 가능한 쿠폰만 세팅
          const validCoupons = checkCouponDate(channelInfo.broad_cpn_list);
          setCoupons(validCoupons);
        }
      }
      setBroadStatus(channelInfo.broad_status); // 방송상태
      checkBroadStartTime(channelInfo); // 방송시간 체크하기
      setEndLoadInfo(true);
    } else {
      console.error("The channel is undefined");
    }
  };

  const loadChannelInfoOnly = async () => {
    if (broadseq !== undefined) {
      const channelParam: any = {
        broad_seq: broadseq,
      };
      const res = await liveChannalApi.get_channel_info(channelParam);
      const channelInfo = res.response.channel_info;
      console.log("loadChannelInfoOnly finish");

      checkBroadStartTime(channelInfo); // 방송시간 체크하기
      setInfo((info: any) => channelInfo);
      setProdList(channelInfo.broad_prod_list);
      // 쿠폰 조회
      if(!queryParams.custNo || queryParams.custNo === "") {
        if(channelInfo.broad_cpn_list) {
          // 쿠폰 다운로드 가능한 쿠폰만 세팅
          const validCoupons = checkCouponDate(channelInfo.broad_cpn_list);
          setCoupons(validCoupons);
        }
      } else {
        getCouponFromParent() // 회원이면 리바트 부모창으로부터 조회
      }
    } else {
      console.error("The channel is undefined");
    }
  };

  // 채팅방 입장 전 사용자 정보 체크
  const procUserJoin = async () => {
    let userId = uuidv4(); // 임시 아이디
    const cookieTempId = window.localStorage.getItem("livartLiveTempId");
    if (cookieTempId !== undefined && cookieTempId !== "" && cookieTempId !== null) {
      userId = cookieTempId;
    } else {
      window.localStorage.setItem("livartLiveTempId", userId); //임시 발급 아이디 쿠키에 저장 후 재사용
    }

    // let userNickName = userId.split("-")[4] + "_";
    let userNickName = "";
    if (queryParams.userId && queryParams.userId !== "tester@test.com") {
      userId = queryParams.userId;
      userNickName = await get_livart_user_nickname(userId);
      if (userNickName === "") {
        userNickName = queryParams.userId.split("@")[0];
      }
    } else {
      // 닉네임 변경기능()
      const cookieNickname = window.localStorage.getItem("livartLiveNickName");
      if (cookieNickname !== undefined && cookieNickname !== "" && cookieNickname !== null) {
        userNickName = cookieNickname;
      }
    }

    setPlayerID(userId);
    setUserState({
      id: userId,
      nickName: userNickName,
      picture: "",
      isAuth: false,
      isAdmin: false,
      isSuperAdmin: false,
      userArn: userId,
    });
  };

  const loadStatisticInfo = async () => {
    if (broadseq !== undefined) {
      const param: any = {
        broad_seq: broadseq,
      };
      const res = await statisticApi.get_statistic_info(param);
      if (res.code === "200") {
        if (!cUtils.isEmptyObj(res.response.statistic_info)) {
          setLikeCount(res.response.statistic_info.total_likes);
          setTotalViewCount(res.response.statistic_info.total_views);
        }
      }
    } else {
      console.error("loadStatisticInfo - The channel is undefined");
    }
  };

  const checkChannelPwd = (channelInfo: any) => {
    var cookie_private_channel = window.localStorage.getItem(appConfig.recent_enter_private_channel);
    if (cookie_private_channel === channelInfo.broad_seq) {
      // 있으면: 입력창 띄우지 말기
      setPasswordConfirm(true);
    } else {
      if (channelInfo.password !== "" && !passwordConfirm) {
        setAskPwdModalCommand("OPEN_MODAL"); // Private 채널이 경우 패스워드 입력
      }
    }
  };

  const checkVodStatus = (channelInfo: any) => {
    if (
      // VOD 상태이며 하이라이트가 있는 경우
      channelInfo.most_concurrent_view_timestamp !== "" &&
      channelInfo.vod_started_at !== "" &&
      channelInfo.most_concurrent_view_timestamp > channelInfo.vod_started_at &&
      channelInfo.broad_status === "VOD"
    ) {
      if (channelInfo.high_start_sec > 0) {
        setVodStartSecHigh(channelInfo.high_start_sec);
        setVodEndSecHigh(channelInfo.high_start_sec + 150);
      } else {
        const date1 = dayjs(channelInfo.most_concurrent_view_timestamp, "YYYYMMDDHHmmss");
        const date2 = dayjs(channelInfo.vod_started_at, "YYYYMMDDHHmmss");
        const diff = date1.diff(date2, "s");
        const enddate = date1.add(150, "s").format("YYYYMMDDHHmmss");
        const endSec = dayjs(enddate, "YYYYMMDDHHmmss").diff(date2, "s");
        if (diff > 0) {
          setVodStartSecHigh(diff);
          setVodEndSecHigh(endSec);
        }
      }

      setVodType("highlight");
      setHaveHighlight(true);
    }
    setVodStartSecFull(channelInfo.vod_start_sec);
    setVodEndSecFull(channelInfo.vod_end_sec);
  };

  const checkBroadStartTime = (channelInfo: any) => {
    const now = dayjs();
    const startTime = dayjs(channelInfo.broad_start_tm, "YYYYMMDDHHmmss");
    const dateDiff = startTime.diff(now, "s");
    if (dateDiff > 0 && channelInfo.broad_status !== "VOD" && channelInfo.broad_status !== "START" && queryParams.mode !== "rehearsal")
      setBroadWait(true);
    else setBroadWait(false);
  };

  // 방송이 송출전이면 방송 남은시간 계산
  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      const remainSec = cUtils.calRemainSec(info.broad_start_tm);
      if (remainSec > 0) setRemainTime(cUtils.convertRemainText(remainSec));
      else {
        loadChannelInfoOnly();
      }
    },
    braodWait ? delay : null
  );

  // 화면 클릭시 메뉴 숨기기
  const fncBodyClick = (e: any) => {
    const width = window.innerWidth;
    if (width < 200) return;
    if (
      e.target === document.getElementById("tab-screen-content") ||
      e.target === document.getElementsByClassName("chat-list")[0] ||
      e.target === document.getElementsByClassName("live-content-chat")[0] ||
      e.target === document.getElementsByClassName("live-coupon-root")[0] ||
      e.target === document.getElementById("divheartActions")
    ) {
      setHiddenMenu((hiddenMenu) => !hiddenMenu);
      setChatOpen(false);
      setControlOpen(true);
      if (!pcMode) liveChatTFRef.current?.handleHiddenForm(true);
    }
  };

  // 사용자 도착 알림
  const chatInitCallback = (result: boolean) => {
    if (queryParams.userId !== "" && queryParams.userId !== undefined && queryParams.userId !== "tester@test.com") {
      // 입장한 사용자의 id와 함께 데이터를 전송한다.
      const topic = `live/${broadseq}/payload`;
      const payload = { message: "userComming", id: queryParams.userId };
      iotRef.current?.sendIoTMessage(topic, payload);
    }
  };

  // 채팅버튼 클릭시
  const fncBtnChatClick = async (status: boolean) => {
    // 비회원 채팅 가능하게 변경
    // if (queryParams.userId !== "") { // 이건 회원만 가능할 때
    if (userState.id !== "") {
      liveChatTFRef.current?.handleHiddenForm(false);
      setChatOpen((chatOpen) => status);
      setHiddenMenu((hiddenMenu) => true);
      setTimeout(() => {
        liveChatRef.current?.scrollToBottom();
      }, 200);
    } else {
      goLoginPage();
    }
  };

  // 채팅 메세지 전송
  const sendMessage = (msg: string, meta: string) => {
    liveChatRef.current?.sendMessage(msg, meta);
  };

  const sendIoTMessage = async (topic: string, chatPayload: any) => {
    iotRef.current?.sendIoTMessage(topic, chatPayload);
  };

  // 좋아요, 누적시청자 등 시스템 메시지 처리용
  async function putMetaFunc(metaName: string) {
    try {
      // VOD IoT 전송
      if (info.broad_status !== "VOD") {
        if (metaName !== "like" && metaName !== "view") {
          const topic = `live/${broadseq}/payload`;
          iotRef.current?.sendIoTMessageMeta(topic, metaName);
        }
      } else {
        const topic = `live/${broadseq}/payload`;
        iotRef.current?.sendIoTMessageMeta(topic, metaName);
      }
      // 좋아요, 시청자수 통계는 iot를 Client가 직접 발송하지 않음
      // 별도의 EC2가 DDB update를 확인하고 주기적으로 전체 iot를 발송함 (iot 계정 quota 문제로 )
      // EC2가 iot를 발송하는 기준은
      // 1. 방송 시작일시가 현재 기준 -7일 ~ +7일 사이일 것
      // 2. 방송 상태가 방송중일 것
      // 3. Stream을 송출중일 것
      if (metaName === "like" || metaName === "view") {
        // 통계 테이블 저장
        const param: any = {
          broad_seq: broadseq,
          prot: metaName,
          user_id: userState.id,
          member: queryParams.userId && queryParams.userId !== "tester@test.com" ? queryParams.userId : "",
          os: _deviceInfo.osName === "iOS" || _deviceInfo.osName === "Android" ? _deviceInfo.osName : "PC",
        };
        if (metaName === "view") {
          // console.log("param : ", param);
        }
        await statisticApi.update_count(param);
      }
    } catch (e) {
      console.error("[ERROR] putMetaFunc : ", e);
    }
  }

  // IVS 초기화 완료
  const ivsInitFinished = () => {
    if (isFirst) {
      // putMetaFunc("view"); // 시청자 View + 1  //// 24.01.04 주석처리.허정은
      isFirst = false;

      // 음소거상태 알림
      if (muted) liveHeaderRef.current?.openAlertMuted();
      liveHeaderRef.current?.initStartIvs();
    }
  };

  // IVS Player의 상태 콜백
  const videoStatusCallback = (status: string) => {
    if (status === "ivsInitStart") {
      setTimeout(() => {
        setIvsInitStart(true);
      }, 3000);
    } else {
      setVideoStatus(status);
      if (status === "onStatePlaying") ivsInitFinished();
      if (status === "unSupported")
        toastRef.current?.toast(
          "지원하지 않는 브라우저입니다. 다른 브라우저로 시도하시거나, 현대리바트 APP으로 이용 바랍니다.",
          "error",
          5000,
          {
            vertical: "top",
            horizontal: "center",
          }
        );
    }
  };

  useEffect(() => {
    if (broadStatus === "START" && !(videoStatus === "onStatePlaying" || videoStatus === "onReady" || videoStatus === "")) {
      console.log("방송을 가져오지 못했습니다.");
      setNoChat(true);
    }
    if (broadStatus === "START" && videoStatus === "onStatePlaying") {
      setNoChat(false);
    }
  }, [videoStatus, broadStatus]);

  // VOD의 경우 비디오의 재생위치 콜백
  const videoPositionCallback = (event: string, pos: number) => {
    const videoPos = dayjs(info.vod_started_at).add(pos, "s").format("YYYYMMDDHHmmss"); // Sec to datetime
    setVideoPosition(videoPos);
  };

  // 볼륨 컨트롤
  const toggleMute = () => {
    let player = livePlayer;
    if (info.broad_status !== "VOD" && rehearsal) player = rehearsalPlayer;
    if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
    if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;

    const muteNext = !player.isMuted();
    player.setMuted(muteNext);
    setMuted(muteNext);
  };

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `live/${broadseq}/payload`) {
      if (payLoad.topic === "chat/message") {
        // 일반 메세지 도착
        liveChatRef.current?.processChannelMessage(payLoad);
      } else if (payLoad.topic === "chat/block") {
        liveChatRef.current?.addBlockList(payLoad);
      } else if (payLoad.topic === "stream/viewerCount") {
        // 라이브 스트림 동시 시청자 건수
        if (payLoad.viewerCount > concurrentView) {
          setConcurrentView(payLoad.viewerCount);
        }
      } else if (payLoad.topic === "game") {
        // 게임 메세지
        if (payLoad.prot === "start") {
          loadChannelGameInfo(payLoad.game_seq);
        } else if (payLoad.prot === "draw") {
          loadChannelGameDrawInfo(payLoad.game_seq);
        }
      } else if (payLoad.topic === "stream/stream_state") {
        // 라이브 스트림 상태변경
        streamEventCallback(payLoad.event_name);
      } else if (payLoad.topic === "live/metadata") {
        if (payLoad.message === "view" && payLoad.senderId !== clientId) viewCountCallback();
        if (payLoad.message === "like") likeCountCallback();
        if (payLoad.message === "like" && payLoad.senderId !== clientId) newLikeUpCallback();
        if (payLoad.message === "start_stream") setStreamStart();
        if (payLoad.message === "ready_stream") setStreamReady();
        if (payLoad.message === "stop_stream") setStreamStop();
        if (payLoad.message === "reloadChannelInfo") loadChannelInfoOnly();
        if (payLoad.message === "userComming") setUserComming(payLoad.id);
        if (payLoad.message === "qna") setQnAArrive(payLoad);
        if (payLoad.message === "apply") applyCallback();
        if (payLoad.message === "chatFontColor") setChatFontColor(payLoad.color);
        // 통계데이터(좋아요, 시청자수 배치 처리)
        if (payLoad.senderId === "statistics_chek_server") {
          const receive_total_likes = payLoad.total_likes;
          const receive_total_views = payLoad.total_views;

          newLikeCountCallback(receive_total_likes);
          newViewUpCallback(receive_total_views);
        }
        if (payLoad.message === "change_live_product") get_live_product();
        if (payLoad.message === "pin_message") setPinMsg(payLoad.text);
      }
    }
  }

  // 이미 화면 로딩이 끝났는데 영상이 아직 출력중이지 않으면 영상이 시잘될때 스트림의 상태에 따라서 비디오를 오토플레이한다.
  const streamEventCallback = (event_name: string) => {
    setStreamState(event_name);
  };

  const btnApplyClickCallback = () => {
    putMetaFunc("apply");
  };

  // 우하단 레이어에서 좋아요 버튼 클릭시 부모창 put meta 함수 호출
  const btnLikeClickCallback = () => {
    putMetaFunc("like");
    if (info.broad_status !== "VOD") {
      likeCountCallback();
    }
  };

  // 좋아요 버튼 클릭시 상단 레이어 전달 -> 좋아요 count 증가
  const likeCountCallback = () => {
    liveHeaderRef.current?.addLiveCount();
  };

  // 다른 사람이 좋아요 눌렀을 때 batch 서버에서 전송된 값 처리
  const newLikeCountCallback = (count: number) => {
    liveHeaderRef.current?.addBatchLikeCount(count);
  };

  // 다른 사람이 입장 했을 때 batch 서버에서 전송된 값 처리
  const newViewUpCallback = (count: number) => {
    liveHeaderRef.current?.addBatchViewCount(count);
  };

  // 다른 사람이 좋아요 누른것을 우하단 레이어에 전달 -> 하트 이벤트 발생
  const newLikeUpCallback = () => {
    liveFooterRef.current?.addNewLiveCount();
  };

  // 게임 응모 클릭 시 -> 응모 count 증가
  const applyCallback = () => {
    liveGameRef.current?.applyNumCallback();
  };

  // 새로운 시청자 인입시 IVS or Chime에서 이벤트를 수신 받기위한 콜백
  const viewCountCallback = () => {
    liveHeaderRef.current?.addViewCount();
  };

  // Private 방송 패스워드 입력 프로세스
  const askPwdModalProcDone = (res: any) => {
    if (res === "SUCCESS") {
      // 1회 패스워드 성공시 쿠키 저장
      if (broadseq !== undefined) window.localStorage.setItem(appConfig.recent_enter_private_channel, broadseq);
      setPasswordConfirm(true);
    } else if (res === "CANCEL") {
      setPasswordConfirm(false);
      windowClose();
    }
  };

  // 로그인 선택시 로그인 페이지 호출
  const askLoginDone = (res: any) => {
    if (res === "YES") {
      goLoginPage();
    } else {
      setAskLogin(false);
    }
  };

  // 로그인 페이지 이동
  const goLoginPage = () => {
    window.location.href= `https://www.hyundailivart.co.kr/login?returnUrl=/liveViewer/${info.livart_broad_seq}/${info.broad_seq}`
  };

  // 창닫기
  const windowClose = () => {
    postMsg({ msg: "goBroadList" }, "*");
  };

  // 방송상태 처리(방송중, 준비중 등)
  const setStreamStart = () => {
    setBroadStatus((broadStatus) => "START");
    loadChannelInfoOnly();
  };
  const setStreamReady = () => {
    setBroadStatus((broadStatus) => "READY");
    loadChannelInfoOnly();
  };
  const setStreamStop = () => {
    setBroadStatus((broadStatus) => "STOP");
    loadChannelInfoOnly();
  };

  // [리바트 전용] 쿠폰, 상품리스트 처리
  const getEventFormParent = (e: any) => {
    // 회원 입장 시 쿠폰 리스트 조회
    console.log(e.data);
    if (e.data.resultData !== undefined && e.data.resultMsg === "SUCCESS") {
      setCoupons(e.data.resultData);
    }
    // 쿠폰 다운로드 시
    if (e.data[0] !== undefined) {
      // 받은 데이터가 쿠폰일 경우
      if(e.data[0].cpnSn !== undefined) {
        let newCoupons = [...e.data];
        setCoupons(newCoupons);
      }
    }
  }

  const getCouponFromParent = () => {
    console.log("SEND getCoupon");
    postMsg({ msg: "getCoupon" }, "*");
  };

  // 쿠폰 표시 여부
  useEffect(() => {
    if (coupons.length > 0) {
      setIsCouponHidden(false)
    } else {
      setIsCouponHidden(true); // 쿠폰이 없으므로 쿠폰 비노출
    }
  }, [coupons]);

  // [리바트 전용] 부모창에 메세지 보내기
  const postMsg = (payload: any, target: any) => {
    if (payload.msg === "shareModal") {
      var sharePayload = payload;
      sharePayload.livartBroadSeq = queryParams.livartBroadSeq;
      window.parent.postMessage(sharePayload, target);
    } else {
      window.parent.postMessage(payload, target);
    }
  };

  // 유저입장 알림
  const setUserComming = (inputId: string) => {
    if (info.broad_status !== "VOD") {
      setUserList((userList: any) => [...userList, inputId]);
      if (!alertUser) setAlertUser(true);
    }
  };

  // 유저입장 알림
  useEffect(() => {
    if (alertUser) {
      const oldUserList: any = [...userList];
      if (oldUserList.length > 1) {
        setAlertUserText(oldUserList[0] + "님외 " + (oldUserList.length - 1) + "명이 들어왔습니다.");
      } else {
        setAlertUserText(oldUserList[0] + "님이 들어왔습니다.");
      }

      let tempUserList: any = [...userList];
      for (let id of oldUserList) {
        for (let i = 0; i < tempUserList.length; i++) {
          if (tempUserList[i] === id) {
            tempUserList.splice(i, 1);
            i--;
          }
        }
      }
      setUserList((userList: any) => [...tempUserList]);
      setTimeout(() => {
        setAlertUser((alertUser) => false);
        setAlertUserText("");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertUser]);

  // QnA 알림
  const setQnAArrive = (payload: any) => {
    const tmpQnA = {
      qContent: payload.q,
      qUserName: payload.qSender,
      aContent: payload.a,
    };
    setNewQnAMsg(tmpQnA);
  };

  // 동시접속자 알림
  useEffect(() => {
    if (concurrentView > 0) {
      const nowConcurrentView = Math.floor(concurrentView / 10);
      if (nowConcurrentView > lastConcurrentView) {
        setConcurrentViewText("동시 접속자가 " + nowConcurrentView * 10 + "명을 돌파했습니다.");
        lastConcurrentView = nowConcurrentView;
        setConcurrentViewFlag(true);
      }
    }
  }, [concurrentView]);

  useEffect(() => {
    if (concurrentViewFlag === true) {
      setTimeout(() => {
        setConcurrentViewFlag(false);
      }, 5000);
    }
  }, [concurrentViewFlag]);

  // APP Floating controls
  const handleNoFloating = useCallback(() => {
    const width = window.innerWidth;
    if (props.webViewStatus === "android" || props.webViewStatus === "ios") {
      if (width > 200) {
        window.location.href = "thehyundai://showpopup";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleYesFloating = useCallback(() => {
    const width = window.innerWidth;
    if (props.webViewStatus === "android" || props.webViewStatus === "ios") {
      if (width > 200) {
        window.location.href = "thehyundai://closepopup";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 브라우저 이벤트
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("message", getEventFormParent);
    return () => {
      // 메모리 누수 방지를 위해 핸들러 클리어
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("message", getEventFormParent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 화면 사이즈로 플로팅 상태 파악 후 메뉴 히든처리
  const handleResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width < 200) {
      // floating 메뉴일때 메뉴 감추기
      setHiddenMenu((hiddenMenu) => true);
      setChatOpen(false);
      setControlOpen(false);
      liveChatTFRef.current?.handleHiddenForm(true);
    } else {
      // Floating 해제시 처리
      if (props.webViewStatus === "android" || props.webViewStatus === "ios") {
        if (height > 500) {
          handleYesFloating();
          setHiddenMenu((hiddenMenu) => false);
          setTimeout(() => {
            liveChatRef.current?.scrollToBottom();
          }, 200);
        } else {
          handleNoFloating();
          setHiddenMenu((hiddenMenu) => true);
          setControlOpen(true);
        }
      }
    }

    // 2023.12.22 허정은 쿼리스트링 pcMode 우선 주석처리
    // if (width > 800 && queryParams.pcMode == "03") {
    if (width > 800) {
      setPcMode(true);
    } else {
      setPcMode(false);
    }
  };

  // VOD 하이라이트, 전체보기
  const toggleVodType = () => {
    if (vodType === "highlight") setVodType((vodType) => "full");
    else setVodType((vodType) => "highlight");
  };

  // IoT 연결 성공
  const iotConnectEnd = () => {
    console.info("iot Connection Success");
    setIotConnect(true);
    // putMetaFunc("view"); //// 24.01.04 주석처리.허정은
  };

  // IoT 연결 끊김
  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    iotRef.current?.connect();
  };

  // 방송 입장 시 진행 중인 게임 리스트 조회
  const loadChannelGameList = async () => {
    if (broadseq !== undefined) {
      const gameParam: any = {
        broad_seq: broadseq,
      };
      const gameListRes = await gameApi.get_game_list(gameParam);
      if (gameListRes.code === "200") {
        setGameList(gameListRes.response.game_list);
      }
      const gameRes = await gameApi.get_live_game_info(gameParam);
      if (gameRes.code === "200") {
        if (!cUtils.isEmptyObj(gameRes.response.game_info)) {
          setGameInfo(gameRes.response.game_info);
          setGameSk((gameSk) => gameRes.response.game_info.sk);
        }
      }
    } else {
      console.error("loadChannelGameList - The channel is undefined");
    }
  };

  // 방송 도중 진행한 게임 목록 조회
  const loadChannelGameInfo = async (sk: string) => {
    if (broadseq !== undefined && sk !== "") {
      const gameParam: any = {
        broad_seq: broadseq,
        sk: sk,
      };
      const gameListRes = await gameApi.get_game_list(gameParam);
      if (gameListRes.code === "200") {
        setGameList(gameListRes.response.game_list);
      }
      const gameRes = await gameApi.get_game_info(gameParam);
      if (gameRes.code === "200") {
        if (!cUtils.isEmptyObj(gameRes.response.game_info)) {
          setGameInfo(gameRes.response.game_info);
          setGameSk((gameSk) => gameRes.response.game_info.sk);
        }
      }
    } else {
      console.error("loadChannelGameInfo - The channel is undefined");
    }
  };

  const loadChannelGameDrawInfo = async (sk: string) => {
    if (broadseq !== undefined && sk !== "") {
      const gameParam: any = {
        broad_seq: broadseq,
        sk: sk,
      };
      const gameRes = await gameApi.get_draw_result(gameParam);
      if (gameRes.code === "200") {
        const userIdList = [];
        for (const draw_result of gameRes.response.result) {
          let user_id = draw_result.user_id;
          userIdList.push(user_id);
        }
        setGameWinner(userIdList);
        setGameDraw(true);
      } else {
        console.error("get_draw_result failed");
      }
    } else {
      console.error("The channel is undefined");
    }
  };

  const drawEventCallback = () => {
    // console.log("drawEventCallback called");
    setGameSk((gameSk) => "");
    setGameDraw(false);
    setGameInfo({});
  };

  useEffect(() => {
    if (videoStatus === "onStatePlaying") {
      let player = livePlayer;
      if (info.broad_status !== "VOD" && rehearsal) player = rehearsalPlayer;
      if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
      if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;

      const videoQlty = player.getQuality();
      if (!cUtils.isEmptyObj(videoQlty)) {
        if (videoQlty.height > videoQlty.width) setVideoMode(1);
        else setVideoMode(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoStatus]);

  const get_live_product = async () => {
    const param: any = {
      command: "get_live_product",
      broad_seq: broadseq,
    };

    const res = await chatApi.post(param);
    if (res.code === "200") {
      setLiveProducts(res.response.live_products);
    }
  };

  const change_livart_user_nickname = async (payload: any) => {
    const param: any = {
      command: "change_livart_user_nickname",
      user_id: payload.userId,
      nickname: payload.nickName,
    };

    const res = await chatApi.post(param);
    if (res.code === "200") {
      procUserJoin();
      toastRef.current?.toast("닉네임을 변경했습니다.", "info", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const get_livart_user_nickname = async (user_id: string) => {
    let nick_name = "";
    const param: any = {
      command: "get_livart_user_nickname",
      user_id: user_id,
    };

    const res = await chatApi.post(param);
    if (res.code === "200") {
      nick_name = res.response.nickname;
    }
    return nick_name;
  };

  const chatCallback = (payload: any) => {
    if (payload.command === "nickname_change") {
      change_livart_user_nickname(payload);
    } else if (payload.command === "nickname_changed") {
      procUserJoin();
      toastRef.current?.toast("닉네임을 변경했습니다.", "info", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    } else if (payload.command === "toast") {
      toastRef.current?.toast(payload.msg, payload.type, 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  // 쿠폰 다운로드 기한 체크
  const checkCouponDate = (couponList: any[]) => {
    let tmpCouponList = [...couponList];
    let newCouponList : any[] = []
    for(let coupon of tmpCouponList) {
        const now = dayjs();
        const downloadStartTime = dayjs(coupon.dnldPsblTermStrtDtime, "YYYYMMDDHHmmss");
        const downloadEndTime = dayjs(coupon.dnldPsblTermFnhDtime, "YYYYMMDDHHmmss")
        
        const startDateDiff = downloadStartTime.diff(now, "s");
        const endDateDiff = downloadEndTime.diff(now, "s");
        
        if(startDateDiff <= 0 && endDateDiff > 0) {
          newCouponList = [...newCouponList, coupon];
        }
    }
    
    return newCouponList;
  }

  const openDrawer = (tab: string) => {
    subModuleRef.current.open(tab)
  }

  return (
    <>
      <div className={pcMode ? "viewer-root-pc" : "viewer-root"}>
        {!passwordConfirm && info.password !== "" && info.password !== undefined ? ( // Private 패스워드 입력창
          <div className="client-video no-scroll">
            <ModalAskPassword modalCommand={askPwdModalCommand} password={info.password} callBack={askPwdModalProcDone} />
          </div>
        ) : !pcMode ? (
          <div className={videoMode === 1 ? "Live-Layout-wrapper small-layout" : "Live-Layout-wrapper"}>
            <div className={videoMode === 1 ? "Live-Layout-inner small-layout-inner" : "Live-Layout-inner"}>
              <div className="Live-Swipe-content">
                <div className="VideoPlayer-wrapper color-snow">
                  {braodWait && info.broad_seq !== "" ? (
                    <BroadWait remainTime={remainTime} broad_info={info} />
                  ) : broadStatus === "STOP" ? (
                    <BroadStop broad_info={info} />
                  ) : info.broad_status !== "VOD" ? (
                    !rehearsal ? (
                      <LiveIVSPlayer
                        broad_seq={info.broad_seq}
                        broad_status={broadStatus}
                        playbackUrl={info.ivs_playback_url}
                        streamState={streamState}
                        videoStatusCallback={videoStatusCallback}
                      />
                    ) : (
                      <ClientIVSPlayer
                        endLoadInfo={endLoadInfo}
                        broad_seq={info.broad_seq}
                        broad_status={broadStatus}
                        playbackUrl={info.ivs_playback_url}
                        streamState={streamState}
                        videoStatusCallback={videoStatusCallback}
                        muted={muted}
                        webViewStatus={props.webViewStatus}
                        rehearsal={rehearsal}
                      />
                    )
                  ) : (
                    <></>
                  )}
                  {info.broad_status === "VOD" && info.broad_seq !== "" ? (
                    vodType === "highlight" ? (
                      <VodIVSPlayerHigh
                        endLoadInfo={endLoadInfo}
                        broad_seq={info.broad_seq}
                        playbackUrl={info.vod_url}
                        vod_start_sec={vodStartSecHigh}
                        vod_end_sec={vodEndSecHigh}
                        videoStatusCallback={videoStatusCallback}
                        videoPositionCallback={videoPositionCallback}
                        hidden_menu={hiddenMenu}
                        muted={muted}
                        controlOpen={controlOpen}
                      />
                    ) : (
                      <VodIVSPlayer
                        endLoadInfo={endLoadInfo}
                        broad_seq={info.broad_seq}
                        playbackUrl={info.vod_url}
                        vod_start_sec={vodStartSecFull}
                        vod_end_sec={vodEndSecFull}
                        videoStatusCallback={videoStatusCallback}
                        videoPositionCallback={videoPositionCallback}
                        hidden_menu={hiddenMenu}
                        muted={muted}
                        controlOpen={controlOpen}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className={
                    hiddenMenu && info.broad_status === "VOD" ? "Tap-screen-wrapper color-snow bottom-50" : "Tap-screen-wrapper color-snow"
                  }
                >
                  <div id="tab-screen-content" className="Tap-screen-content" onClick={fncBodyClick}>
                    <div className="live-header-root">
                      {info.broad_seq !== "" ? (
                        <LiveHeader
                          ref={liveHeaderRef}
                          broad_seq={info.broad_seq}
                          broad_title={info.broad_title}
                          broad_status={broadStatus}
                          video_status={videoStatus}
                          host_picture={info.host_picture}
                          host_name={info.host_name}
                          postMsg={postMsg}
                          tot_view_count={totalViewCount}
                          tot_like_count={likeCount}
                          hidden_menu={hiddenMenu}
                          vodType={vodType}
                          haveHighlight={haveHighlight}
                          toggleVodType={toggleVodType}
                          muted={muted}
                          toggleMute={toggleMute}
                          braodWait={braodWait}
                          pcMode={pcMode}
                          showLikeEmoji={newLikeUpCallback}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* <ModalDownloadCoupon title_message="쿠폰 다운로드 완료" content_message="LIVE 쿠폰이 정상 발급 되었습니다." ref={couponDownloadRef}/>  */}
                    {info.broad_seq !== "" &&
                      // info.broad_status === "START" &&
                      !braodWait &&
                      // 커밍순 아니고, 방송상태 방송중일 때
                      (!isCouponHidden ? (
                        <div className="live-coupon-root">
                          <LiveCoupon
                            coupons={coupons}
                            hidden_menu={hiddenMenu}
                            openDrawer={openDrawer}
                          />
                        </div>
                      ) : (
                        <></>
                      ))}
                    <div className="live-content-root">
                      <div
                        className={
                          hiddenMenu && !chatOpen ? "live-content-wrapper chat-bg-transparent" : "live-content-wrapper chat-bg-gradient"
                        }
                      >
                        {concurrentViewFlag === true && !hiddenMenu && !braodWait && broadStatus !== "STOP" ? (
                          <div className="live-user-comming-alert">
                            <div className="live-user-comming-alert-wrapper">
                              <div className="live-concurrent-view-body live-alert-box">
                                <Box>{concurrentViewText}</Box>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {alertUserText && !hiddenMenu ? (
                          info.broad_seq !== "" && info.broad_status !== "VOD" && !braodWait && broadStatus !== "STOP" ? (
                            <div className="live-user-comming-alert">
                              <div className="live-user-comming-alert-wrapper">
                                <div className="live-user-comming-alert-body live-alert-box">
                                  <Box>{alertUserText}</Box>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                        <div className="live-content-chat">
                          {iotConnect &&
                            userState.userArn !== "" &&
                            info.broad_status !== "VOD" &&
                            !braodWait &&
                            broadStatus !== "STOP" && (
                              <ChatVerNoSdk
                                ref={liveChatRef}
                                broad_seq={info.broad_seq}
                                broad_status={info.broad_status}
                                channelArn={info.chime_channel_arn}
                                adminArn={info.chime_admin_arn}
                                chat_open={chatOpen}
                                hidden_menu={hiddenMenu}
                                chatInitCallback={chatInitCallback}
                                userState={userState}
                                chatFontColor={chatFontColor}
                                sendIoTMessage={sendIoTMessage}
                                chat_height="30vh"
                                pcMode={pcMode}
                                noChat={noChat}
                                setLiveProducts={setLiveProducts}
                                setPinMsg={setPinMsg}
                                livart_id={queryParams.userId}
                                callback={chatCallback}
                              />
                            )}
                          {iotConnect && playerID !== "" && info.broad_status === "VOD" && !braodWait && broadStatus !== "STOP" && (
                            <ChatVerVod
                              ref={liveChatRef}
                              broad_seq={info.broad_seq}
                              channelArn={info.chime_channel_arn}
                              adminArn={info.chime_admin_arn}
                              chatUrl={chatUrl}
                              chat_open={chatOpen}
                              screenMode={screenMode}
                              hidden_menu={hiddenMenu}
                              chatInitCallback={chatInitCallback}
                              player_id={playerID}
                              streamEventCallback={streamEventCallback}
                              videoPosition={videoPosition}
                              vod_started_at={info.vod_started_at}
                              userState={userState}
                              isNotFloat={handleNoFloating}
                              isFloat={handleYesFloating}
                              pcMode={pcMode}
                              setLiveProducts={setLiveProducts}
                              setPinMsg={setPinMsg}
                            />
                          )}
                        </div>
                        {info.broad_seq !== "" && (broadStatus === "START" || broadStatus === "VOD") && pinMsg !== "" ? (
                          <div
                            className={
                              hiddenMenu
                                ? "display-none"
                                : info.broad_status === "VOD" && prodList.length === 0 && info.broad_notice === ""
                                ? "live-content-pin-msg mb-17"
                                : "live-content-pin-msg"
                            }
                          >
                            <LivePinMsg broad_info={info} isNotFloat={handleNoFloating} isFloat={handleYesFloating} msg={pinMsg} />
                            <Box sx={{ mb: 1 }}></Box>
                          </div>
                        ) : (
                          <></>
                        )}
                        {info.broad_seq !== "" && broadStatus !== "STOP" && info.broad_notice !== "" ? (
                          <div
                            className={
                              hiddenMenu
                                ? "display-none"
                                : info.broad_status === "VOD" && prodList.length === 0
                                ? "live-content-notice mb-17"
                                : "live-content-notice"
                            }
                          >
                            {broadStatus === "VOD" ? (
                              <VodNotice broad_info={info} isNotFloat={handleNoFloating} isFloat={handleYesFloating} />
                            ) : (
                              <LiveNotice isNotFloat={handleNoFloating} isFloat={handleYesFloating} broad_info={info} />
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {gameSk !== "" && gameInfo?.flag === "apply" && !braodWait && broadStatus !== "STOP" ? (
                          <div className={hiddenMenu ? "display-none" : "live-content-game"}>
                            {/* <LiveGame
                              ref={liveGameRef}
                              gameInfo={gameInfo}
                              userId={queryParams.userId}
                              custNo={queryParams.custNo}
                              nickName={userState.nickName}
                              applyClick={btnApplyClickCallback}
                              goLoginPage={goLoginPage}
                            /> */}
                          </div>
                        ) : (
                          <></>
                        )}
                        {gameDraw && !braodWait && broadStatus !== "STOP" ? (
                          <div className={hiddenMenu ? "display-none" : "live-game-draw-root"}>
                            <LiveGameDraw gameWinner={gameWinner} drawEventCallback={drawEventCallback} userId={queryParams.userId} />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          className={
                            info.broad_status === "VOD" && prodList.length === 0 ? "live-content-th-absolute" : "live-content-footer"
                          }
                        >
                          {info.broad_seq !== "" && broadStatus !== "STOP" ? (
                            <LiveProduct
                              isNotFloat={handleNoFloating}
                              isFloat={handleYesFloating}
                              hidden_menu={hiddenMenu}
                              prodList={prodList}
                              postMsg={postMsg}
                              broadSeq={info.broad_seq}
                              userId={userState.id}
                              hostId={info.host_id}
                              liveProducts={liveProducts}
                              cnslImgPath={info.cnslImgPath}
                              cnslPageUrl={info.cnslPageUrl}
                              bnrImgPath={info.bnrImgPath}
                              bnrPageUrl={info.bnrPageUrl}
                              loadChannelInfoOnly={loadChannelInfoOnly}
                              openDrawer={openDrawer}
                            />
                          ) : (
                            <></>
                          )}
                          {info.broad_seq !== "" && !braodWait && broadStatus !== "STOP" ? (
                            <LiveFooter
                              ref={liveFooterRef}
                              isNotFloat={handleNoFloating}
                              isFloat={handleYesFloating}
                              broad_seq={info.broad_seq}
                              broad_status={info.broad_status}
                              broad_title={info.broad_title}
                              broad_img={info.broad_cover_url}
                              livart_broad_seq={livartBroadSeq}
                              user_id={userState.id}
                              like_click={btnLikeClickCallback}
                              hidden_menu={hiddenMenu}
                              sendMessage={sendMessage}
                              prodList={prodList}
                              postMsg={postMsg}
                              newQnAMsg={newQnAMsg}
                              openChatInput={fncBtnChatClick}
                              videoMode={videoMode}
                              pcMode={pcMode}
                              videoStatus={videoStatus}
                              rehearsal={rehearsal}
                              hostId={info.host_id}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <LiveChatTF
                          ref={liveChatTFRef}
                          broad_status={info.broad_status}
                          user_id={userState.id}
                          nickName={userState.nickName}
                          livart_id={queryParams.userId}
                          sendMessage={sendMessage}
                          pcMode={pcMode}
                          callback={chatCallback}
                        />
                        <PrdCpnModules 
                          userId={userState.id}
                          custNo={queryParams.custNo}
                          broad_info={info}
                          isNotFloat={handleNoFloating}
                          isFloat={handleYesFloating}
                          prodList={prodList}
                          liveProducts={liveProducts}
                          couponList={coupons}
                          videoMode={videoMode}
                          goLoginPage={goLoginPage}
                          postMsg={postMsg}
                          ref={subModuleRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {gameList.length > 0 && !braodWait && broadStatus !== "STOP" && (
              <Box sx={{ position: "absolute", top: "150px", left: "10px", zIndex: "999", display: hiddenMenu ? "none" : "flex" }}>
                <LiveGame
                  userId={queryParams.userId}
                  custNo={queryParams.custNo}
                  nickName={userState.nickName}
                  gameList={gameList}
                  callback={(payload: any) => {
                    if (payload.command === "apply_game") {
                      btnApplyClickCallback();
                      loadChannelGameList();
                    } else if (payload.command === "need_login") {
                      goLoginPage();
                    }
                  }}
                />
              </Box>
            )}
          </div>
        ) : (
          // 3분할 ---> 여기서부터 PC모드
          <Box sx={{ flexGrow: 1, width: "100%", height: "100%" }}>
            <Grid container spacing={2} sx={{ height: "100%", maxWidth: "1300px", margin: "auto", padding: "80px" }}>
              <Grid item xs={4} sx={{ height: "100%" }}>
                <Paper elevation={10} sx={{ height: "100%", borderRadius: "0.5rem", position: "relative" }}>
                  <div className="VideoPlayer-wrapper color-snow broder-radius-05">
                    {braodWait && info.broad_seq !== "" ? (
                      <BroadWait remainTime={remainTime} broad_info={info} />
                    ) : broadStatus === "STOP" ? (
                      <BroadStop broad_info={info} />
                    ) : info.broad_status !== "VOD" && pcMode ? (
                      !rehearsal ? (
                        <LiveIVSPlayer
                          broad_seq={info.broad_seq}
                          broad_status={broadStatus}
                          playbackUrl={info.ivs_playback_url}
                          streamState={streamState}
                          videoStatusCallback={videoStatusCallback}
                        />
                      ) : (
                        <ClientIVSPlayer
                          endLoadInfo={endLoadInfo}
                          broad_seq={info.broad_seq}
                          broad_status={broadStatus}
                          playbackUrl={info.ivs_playback_url}
                          streamState={streamState}
                          videoStatusCallback={videoStatusCallback}
                          muted={muted}
                          webViewStatus={props.webViewStatus}
                          rehearsal={rehearsal}
                        />
                      )
                    ) : (
                      <></>
                    )}
                    {info.broad_status === "VOD" && info.broad_seq !== "" && pcMode ? (
                      vodType === "highlight" ? (
                        <VodIVSPlayerHigh
                          endLoadInfo={endLoadInfo}
                          broad_seq={info.broad_seq}
                          playbackUrl={info.vod_url}
                          vod_start_sec={vodStartSecHigh}
                          vod_end_sec={vodEndSecHigh}
                          videoStatusCallback={videoStatusCallback}
                          videoPositionCallback={videoPositionCallback}
                          hidden_menu={hiddenMenu}
                          muted={muted}
                          controlOpen={controlOpen}
                        />
                      ) : (
                        <VodIVSPlayer
                          endLoadInfo={endLoadInfo}
                          broad_seq={info.broad_seq}
                          playbackUrl={info.vod_url}
                          vod_start_sec={vodStartSecFull}
                          vod_end_sec={vodEndSecFull}
                          videoStatusCallback={videoStatusCallback}
                          videoPositionCallback={videoPositionCallback}
                          hidden_menu={hiddenMenu}
                          muted={muted}
                          controlOpen={controlOpen}
                        />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={
                      hiddenMenu && info.broad_status === "VOD"
                        ? "Tap-screen-wrapper color-snow bottom-50"
                        : "Tap-screen-wrapper color-snow"
                    }
                  >
                    <div id="tab-screen-content" className="Tap-screen-content" onClick={fncBodyClick}>
                      <div className="live-header-root">
                        {info.broad_seq !== "" ? (
                          <LiveHeader
                            ref={liveHeaderRef}
                            broad_seq={info.broad_seq}
                            broad_title={info.broad_title}
                            broad_status={broadStatus}
                            video_status={videoStatus}
                            host_picture={info.host_picture}
                            host_name={info.host_name}
                            postMsg={postMsg}
                            tot_view_count={totalViewCount}
                            tot_like_count={likeCount}
                            hidden_menu={hiddenMenu}
                            vodType={vodType}
                            haveHighlight={haveHighlight}
                            toggleVodType={toggleVodType}
                            muted={muted}
                            toggleMute={toggleMute}
                            braodWait={braodWait}
                            pcMode={pcMode}
                            showLikeEmoji={newLikeUpCallback}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="live-content-root">
                        <div
                          className={
                            hiddenMenu && !chatOpen ? "live-content-wrapper chat-bg-transparent" : "live-content-wrapper chat-bg-gradient"
                          }
                        >
                          {concurrentViewFlag === true && !hiddenMenu && !braodWait && broadStatus !== "STOP" ? (
                            <div className="live-user-comming-alert">
                              <div className="live-user-comming-alert-wrapper">
                                <div className="live-concurrent-view-body live-alert-box">
                                  <Box>{concurrentViewText}</Box>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {alertUserText && !hiddenMenu ? (
                            info.broad_seq !== "" && info.broad_status !== "VOD" && !braodWait && broadStatus !== "STOP" ? (
                              <div className="live-user-comming-alert">
                                <div className="live-user-comming-alert-wrapper">
                                  <div className="live-user-comming-alert-body live-alert-box">
                                    <Box>{alertUserText}</Box>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                          {info.broad_seq !== "" && (broadStatus === "START" || broadStatus === "VOD") && pinMsg !== "" ? (
                            <div
                              className={
                                hiddenMenu
                                  ? "display-none"
                                  : info.broad_status === "VOD" && prodList.length === 0 && info.broad_notice === ""
                                  ? "mb-17"
                                  : ""
                              }
                            >
                              <LivePinMsgPc
                                broad_info={info}
                                isNotFloat={handleNoFloating}
                                isFloat={handleYesFloating}
                                msg={pinMsg}
                                hidden_menu={hiddenMenu}
                              />
                              <Box sx={{ mb: 1 }}></Box>
                            </div>
                          ) : (
                            <></>
                          )}
                          {info.broad_seq !== "" && broadStatus !== "STOP" ? (
                            <div
                              className={hiddenMenu ? "display-none" : info.broad_status === "VOD" && prodList.length === 0 ? "mb-17" : ""}
                            >
                              {broadStatus === "VOD" ? (
                                <VodNoticePC
                                  isNotFloat={handleNoFloating}
                                  isFloat={handleYesFloating}
                                  broad_info={info}
                                  hidden_menu={hiddenMenu}
                                />
                              ) : (
                                <LiveNoticePC
                                  isNotFloat={handleNoFloating}
                                  isFloat={handleYesFloating}
                                  broad_info={info}
                                  hidden_menu={hiddenMenu}
                                />
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                          {gameSk !== "" && !braodWait && broadStatus !== "STOP" ? (
                            <div className={hiddenMenu ? "display-none" : "live-content-game"}>
                              {/* <LiveGame
                                ref={liveGameRef}
                                gameInfo={gameInfo}
                                userId={queryParams.userId}
                                custNo={queryParams.custNo}
                                nickName={userState.nickName}
                                applyClick={btnApplyClickCallback}
                                goLoginPage={goLoginPage}
                              /> */}
                            </div>
                          ) : (
                            <></>
                          )}
                          {gameDraw && !braodWait && broadStatus !== "STOP" ? (
                            <div className={hiddenMenu ? "display-none" : "live-game-draw-root"}>
                              <LiveGameDraw gameWinner={gameWinner} drawEventCallback={drawEventCallback} userId={queryParams.userId} />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div
                            className={
                              info.broad_status === "VOD" && prodList.length === 0 ? "live-content-th-absolute" : "live-content-footer"
                            }
                          >
                            {info.broad_seq !== "" && !braodWait && broadStatus !== "STOP" ? (
                              <LiveFooter
                                ref={liveFooterRef}
                                isNotFloat={handleNoFloating}
                                isFloat={handleYesFloating}
                                broad_seq={info.broad_seq}
                                broad_status={info.broad_status}
                                broad_title={info.broad_title}
                                broad_img={info.broad_cover_url}
                                livart_broad_seq={livartBroadSeq}
                                user_id={userState.id}
                                like_click={btnLikeClickCallback}
                                hidden_menu={hiddenMenu}
                                sendMessage={sendMessage}
                                prodList={prodList}
                                postMsg={postMsg}
                                newQnAMsg={newQnAMsg}
                                openChatInput={fncBtnChatClick}
                                videoMode={videoMode}
                                pcMode={pcMode}
                                videoStatus={videoStatus}
                                rehearsal={rehearsal}
                                hostId={info.host_id}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {gameList.length > 0 && !braodWait && broadStatus !== "STOP" && (
                    <Box sx={{ position: "absolute", top: "150px", left: "0px", zIndex: "999", display: hiddenMenu ? "none" : "flex" }}>
                      <LiveGamePc
                        userId={queryParams.userId}
                        custNo={queryParams.custNo}
                        nickName={userState.nickName}
                        gameList={gameList}
                        callback={(payload: any) => {
                          if (payload.command === "apply_game") {
                            btnApplyClickCallback();
                            loadChannelGameList();
                          } else if (payload.command === "need_login") {
                            goLoginPage();
                          }
                        }}
                      />
                    </Box>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={4} sx={{ height: "100%" }}>
                <Paper elevation={10} sx={{ height: "100%", borderRadius: "0.5rem" }}>
                  <div className="live-content-chat height-100 pb-10">
                    {iotConnect &&
                      userState.userArn !== "" &&
                      info.broad_status !== "VOD" &&
                      !braodWait &&
                      broadStatus !== "STOP" &&
                      pcMode && (
                        <ChatVerNoSdk
                          ref={liveChatRef}
                          broad_seq={info.broad_seq}
                          broad_status={info.broad_status}
                          channelArn={info.chime_channel_arn}
                          adminArn={info.chime_admin_arn}
                          chat_open={chatOpen}
                          hidden_menu={pcMode ? false : hiddenMenu}
                          chatInitCallback={chatInitCallback}
                          userState={userState}
                          chatFontColor={"rgb(0, 0, 0)"}
                          sendIoTMessage={sendIoTMessage}
                          chat_height="100%"
                          pcMode={pcMode}
                          noChat={noChat}
                          setLiveProducts={setLiveProducts}
                          setPinMsg={setPinMsg}
                          livart_id={queryParams.userId}
                          callback={chatCallback}
                        />
                      )}
                    {iotConnect && playerID !== "" && info.broad_status === "VOD" && !braodWait && broadStatus !== "STOP" && pcMode && (
                      <ChatVerVod
                        ref={liveChatRef}
                        broad_seq={info.broad_seq}
                        channelArn={info.chime_channel_arn}
                        adminArn={info.chime_admin_arn}
                        chatUrl={chatUrl}
                        chat_open={chatOpen}
                        screenMode={screenMode}
                        hidden_menu={pcMode ? false : hiddenMenu}
                        chatInitCallback={chatInitCallback}
                        player_id={playerID}
                        streamEventCallback={streamEventCallback}
                        videoPosition={videoPosition}
                        vod_started_at={info.vod_started_at}
                        userState={userState}
                        isNotFloat={handleNoFloating}
                        isFloat={handleYesFloating}
                        pcMode={pcMode}
                        setLiveProducts={setLiveProducts}
                        setPinMsg={setPinMsg}
                      />
                    )}
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={4} sx={{ height: "100%" }}>
                <Paper elevation={10} sx={{ height: "100%", borderRadius: "0.5rem" }}>
                  <PrdCpnModulesPC 
                    userId={userState.id}
                    custNo={queryParams.custNo}
                    broad_info={info}
                    prodList={prodList}
                    liveProducts={liveProducts}
                    couponList={coupons}
                    videoMode={videoMode}
                    goLoginPage={goLoginPage}
                    postMsg={postMsg}
                    ref={subModuleRef}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
      <AskLoginDialog command={askLogin} callBack={askLoginDone} />
      {broadseq !== undefined && (
        <IoTClient
          ref={iotRef}
          broadSeq={broadseq}
          iotRegion={appConfig.iotRegion}
          iotIdentityPollId={appConfig.iotIdentityPollId}
          iotEndPoint={appConfig.iotEndPoint}
          recieveIoTMessage={recieveIoTMessage}
          iotConnectEnd={iotConnectEnd}
          iotLost={iotLost}
        />
      )}
      <Toast2 ref={toastRef} />
      {ivsInitStart && broadStatus === "START" && videoStatus !== "onStatePlaying" && !pcMode && _deviceInfo.osName === "iOS" && (
        <StreamErrorNotice />
      )}
    </>
  );
};

export default LivePlayer;
