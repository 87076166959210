import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LiveLike from "../like/LiveLike";

import LiveShare from "../share/LiveShare";
import LiveQnA from "../qna/LiveQnA";
import LiveQnAPc from "../qna/LiveQnAPc";
import LiveChatBtn from "../chat/LiveChatBtn";
import "./LiveFooter.css";

interface propsType {
  broad_seq: string;
  broad_status: string;
  broad_title: string;
  broad_img: string;
  livart_broad_seq: string;
  user_id: string;
  like_click: any;
  hidden_menu: boolean;
  sendMessage: any;
  prodList: any;
  postMsg: any;
  isNotFloat: any;
  isFloat: any;
  newQnAMsg: any;
  openChatInput: any;
  videoMode: number;
  pcMode: boolean;
  videoStatus: string;
  rehearsal: boolean;
  hostId: any; // 컨설팅 링크 구분용
}

const LiveFooter = (props: propsType, ref: any) => {
  const iconSize: string = "44px";
  const [newLikeCount, setNewLikeCount] = useState(0);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setNewLikeCount(0);
    };
  }, []);

  const addNewLiveCount = () => {
    setNewLikeCount((newLikeCount: number) => newLikeCount + 1);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    addNewLiveCount,
  }));

  return (
    <>
      <Box component="div" sx={{ width: "100%", padding: "8px 14px" }} className="color-snow">
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "100%",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            // marginBottom: `${props.hidden_menu && props.chat_open ? "41px" : "0"}`
          }}
        >
          <Stack
            direction="column"
            sx={{ padding: `${props.broad_status === "VOD" ? "0 8px" : "8px"}`, position: "absolute", right: "3%" }}
            spacing={1}
          >
            <LiveLike
              like_click={props.like_click}
              new_like_count={newLikeCount}
              hidden_menu={props.hidden_menu}
              iconSize={iconSize}
              noEffect={false}
            />
            <LiveShare
              isNotFloat={props.isNotFloat}
              isFloat={props.isFloat}
              hidden_menu={props.hidden_menu}
              broad_seq={props.broad_seq}
              broad_title={props.broad_title}
              broad_img={props.broad_img}
              livart_broad_seq={props.livart_broad_seq}
              postMsg={props.postMsg}
              iconSize={iconSize}
            />
            {props.pcMode ? (
              <LiveQnAPc
                isNotFloat={props.isNotFloat}
                isFloat={props.isFloat}
                hidden_menu={props.hidden_menu}
                newQnAMsg={props.newQnAMsg}
                broad_seq={props.broad_seq}
                iconSize={iconSize}
                videoMode={props.videoMode}
              />
            ) : (
              <LiveQnA
                isNotFloat={props.isNotFloat}
                isFloat={props.isFloat}
                hidden_menu={props.hidden_menu}
                newQnAMsg={props.newQnAMsg}
                broad_seq={props.broad_seq}
                iconSize={iconSize}
                videoMode={props.videoMode}
              />
            )}

            {!props.pcMode &&
              (props.rehearsal ||
                (props.broad_status === "START" && (
                  // || (props.broad_status === "START" && props.videoStatus === "onStatePlaying")
                  <LiveChatBtn
                    broad_status={props.broad_status}
                    user_id={props.user_id}
                    hidden_menu={props.hidden_menu}
                    sendMessage={props.sendMessage}
                    iconSize={iconSize}
                    openChatInput={props.openChatInput}
                  />
                )))}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default forwardRef(LiveFooter);
