import { useEffect, useState, useRef } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { androidWebAppInterface } from "../../../interface/MainInterface";
import { appConfig } from "../../../../config/Config";
import Toast from "../../../Utils/Toast";
import "./LiveShare.css";

interface propsType {
  broad_seq: string;
  iconSize: string;
  postMsg: any;
  hidden_menu: boolean;
  isNotFloat: any;
  isFloat: any;
  queryParams: any;
  broad_info: any;
}

declare const window: any;
declare var android: androidWebAppInterface;

const LiveShareAndroid = (props: propsType) => {
  if (!window.Kakao.isInitialized()) {
    window.Kakao.init(appConfig.kakao_app_key);
  }

  const toastRef: any = useRef();

  const [openShare, setOpenShare] = useState(false);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenShare(false);
    };
  }, []);

  const handleShareModalClose = () => {
    setOpenShare(false);
    props.isFloat();
  };
  const handleShareModalOpen = () => {
    // getBroadQnaList(props.broad_seq);
    setOpenShare(true);
    console.log("handleShareModalOpen: 더현대 부모 웹뷰로 소셜공유 모달 요청했습니다.");
    // 더현대로 소셜공유 모달 요청
    // props.postMsg({ msg: "shareModal" }, "*");
    props.isNotFloat();
  };

  return (
    <>
      <Box sx={props.hidden_menu ? { display: "none" } : { display: "block" }} onClick={handleShareModalOpen}>
        <img className="footer-right-icon" src="/images/btn-share.png" alt="" />
      </Box>
      <Dialog
        open={openShare}
        onClose={handleShareModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "1rem", width: "80%" } }}
      >
        <DialogContent>
          <Box className="live-qna-drawer-top">
            <Box component="img" src="/images/icon-share.png" className="share-title-icon" />
            <div className="share-title-text">공유하기</div>
            <Box className="live-qna-drawer-close">
              <Box component="img" src="/images/notice-close.svg" onClick={handleShareModalClose} />
            </Box>
          </Box>
          <Box>
            <Stack spacing={2} direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
              {/* 카카오톡 */}
              <Stack
                spacing={0}
                direction="column"
                onClick={() => {
                  try {
                    android.shareKakao();
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <Box>
                  <img className="share-icon" src="/images/icon-kakao.png" alt="카카오톡 공유하기" />
                </Box>
                <Box className="share-small-text">카카오톡</Box>
              </Stack>
              {/* 링크복사 */}
              <Stack
                spacing={0}
                direction="column"
                onClick={() => {
                  try {
                    android.shareLink();
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <Box>
                  <img className="share-icon" src="/images/icon-linkcopy.png" alt="링크복사" />
                </Box>
                <Box className="share-small-text">링크복사</Box>
              </Stack>
              {/* SMS */}
              <Stack
                spacing={0}
                direction="column"
                onClick={() => {
                  try {
                    android.shareSMS();
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <Box>
                  <img className="share-icon" src="/images/icon-sms.png" alt="SMS" />
                </Box>
                <Box className="share-small-text">SMS</Box>
              </Stack>
              {/* 더보기 */}
              <Stack
                spacing={0}
                direction="column"
                onClick={() => {
                  try {
                    android.shareMore();
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <Box>
                  <img className="share-icon" src="/images/icon-more.png" alt="더보기" />
                </Box>
                <Box className="share-small-text">더보기</Box>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <Toast ref={toastRef} />
      </Dialog>
    </>
  );
};

export default LiveShareAndroid;
