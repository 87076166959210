import { useState, forwardRef, useImperativeHandle, useCallback, useEffect, useRef } from "react";
import { Box, Button, CardMedia, Divider, Drawer, IconButton, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { HttpGameApi } from "../../../interface/game-rest-api";

import GameRow from "./GameRow";
import GameDetail from "./GameDetail";

import "./LiveGameList.css";

interface propsType {
  userId: string;
  custNo: string;
  nickName: string;
  gameList: any;
  callback: any;
}

const gameApi = new HttpGameApi();

const LiveGameList = (props: propsType, ref: any) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedGame, setSelectedGame] = useState<any>(null);

  const [checkFinish, setCheckFinish] = useState(false);
  const [alreadyJoin, setAlreadyJoin] = useState(true);

  useEffect(() => {
    return () => {
      setOpenDrawer(false);
      setSelectedGame(null);

      setCheckFinish(false);
      setAlreadyJoin(true);
    };
  }, []);

  const open = useCallback(() => {
    setOpenDrawer(false);
    setSelectedGame(null);
    setCheckFinish(false);
    setAlreadyJoin(true);
    setOpenDrawer(true);
  }, []);

  const close = () => {
    setOpenDrawer(false);
  };

  // 이미 게임에 참여했는지 확인
  const checkGameState = async () => {
    setCheckFinish(false);
    const param: any = {
      game_info: selectedGame,
      user_id: props.userId,
    };

    const res = await gameApi.check_game(param);
    console.log("res : ", res);
    if (res.response.result === "exist") setAlreadyJoin(true);
    else setAlreadyJoin(false);
    setCheckFinish(true);
  };

  useEffect(() => {
    if (selectedGame) checkGameState();
  }, [selectedGame]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Drawer
        anchor={"bottom"}
        open={openDrawer}
        onClose={() => {
          close();
        }}
        PaperProps={{
          sx: {
            left: 0,
            right: 0,
            height: "600px",
            borderRadius: "20px 20px 0px 0px",
          },
        }}
      >
        {!selectedGame ? (
          <Box className="live-game-list-root">
            <Stack direction={"column"} spacing={0} className="wrap">
              <Box className="header">
                <IconButton
                  onClick={() => {
                    close();
                  }}
                  sx={{ p: 0 }}
                >
                  <CloseIcon sx={{ fontSize: "30px", color: "#BBBBBB" }} />
                </IconButton>
              </Box>
              <Box>라이브 이벤트</Box>
              <Box sx={{ height: "16px" }}></Box>
              <Box>
                <Stack direction={"column"} spacing={2}>
                  {props.gameList.map((game: any, index: number) => (
                    <GameRow
                      key={`live-game-${index}`}
                      userId={props.userId}
                      gameInfo={game}
                      callback={(payload: any) => {
                        if (payload.command === "select_game") setSelectedGame(game);
                      }}
                    />
                  ))}
                </Stack>
              </Box>
            </Stack>
          </Box>
        ) : (
          <GameDetail
            userId={props.userId}
            custNo={props.custNo}
            nickName={props.nickName}
            gameInfo={selectedGame}
            mode={"mo"}
            callback={(payload: any) => {
              if (payload.command === "go_game_list") setSelectedGame(null);
              else if (payload.command === "apply_game") props.callback(payload);
              else if (payload.command === "need_login") props.callback(payload);
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default forwardRef(LiveGameList);
