import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Amplify from "aws-amplify";
import awsExports from "./config/aws-exports";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import MainContainer from "./components/MainContainer";
import { userState } from "./components/interface/MainInterface";
Amplify.configure(awsExports);

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

let userParam: userState = {
  id: "",
  nickName: "",
  picture: "",
  isAuth: false,
  isAdmin: false,
  isSuperAdmin: false,
  userArn: "",
};

const theme = createTheme({
  typography: {
    fontFamily: "NanumBarunGothic, Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'NanumBarunGothic';
          font-style: normal;
          font-display: swap;
          font-weight: 1;
          src: local('NanumBarunGothic'), local('NanumBarunGothic-Regular'), url('./fonts/NanumBarunGothic.woff') format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  palette: {
    secondary: {
      main: "#e90909",
    },
  },
});

const App: React.FC = () => {
  const [userState, setUserState] = useState<userState>(userParam);
  const [webViewStatus, setWebViewStatus] = useState("web");

  useEffect(() => {
    var broswerInfo = window.navigator.userAgent;
    if (broswerInfo.indexOf("Web_Android") > -1) {
      setWebViewStatus("android");
    } else if (broswerInfo.indexOf("AppStore") > -1) {
      setWebViewStatus("ios");
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <MainContainer userState={userState} webViewStatus={webViewStatus} />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

export default App;
