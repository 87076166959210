import { Box, Typography } from "@mui/material";
import { CommonUtils } from "../../../Utils/common_utils";

import "./LiveCoupon.css";

type CouponType = {
  cpnSn: string // 쿠폰번호
  cpnNm: string // 쿠폰명
  cpnTypeCd: string // 쿠폰 유형 코드 (String) 10 : 상품할인 / 20 : 장바구니 할인 / 30: 배송비 할인
  cpnStCd: string // 쿠폰 상태 코드 (String) 20 :사용 / 30 :중지 / 40:만료
  cpnAplyMethCd: string // 쿠폰 적용 방법 코드  10 : 정액(원) / 20 : 정률(%)
  cpnAplyVal: number // 쿠폰 적용 값
  cpnTgtCd: string // 쿠폰 적용 대상 (String) 10 : 상품 / 20: 카테고리 / 30:브랜드 / 40:함께 사면 할인 / 00 : 전체
  minPurAmt: number // 최소 구매 금액
  maxDscntAmt: number // 최대 할인 금액
  mbrMaxIssuQty: number // 회원 최대 발급 수량
  dnldPsblTermStrtDtime: string // 쿠폰 사용 시작 일시
  dnldPsblTermFnhDtime: string // 쿠폰 사용 종료 일시
}

interface propsType {
  coupons: CouponType[];
  hidden_menu: boolean;
  openDrawer: Function
}

const cUtils = new CommonUtils();

export default function LiveCoupon({ coupons, hidden_menu, openDrawer} : propsType) {

  const openCouponList = () => {
    openDrawer("coupon")
  }

  return (
    <Box
      className="live-coupon-img-root"
      sx={hidden_menu ? {display: "none"} : { display: "flex", zIndex: "1000"}}>
      <Box className="live-coupon-img-container"
        onClick={openCouponList}>
        <Box className="live-coupon-price">
          <Typography className={coupons[0].cpnAplyMethCd === "10" ? "live-coupon-txt-num" : "live-coupon-txt-rate"}>
            {cUtils.numericComma(coupons[0].cpnAplyVal)}
            {coupons[0].cpnAplyMethCd === "10" ? "원" : "%"}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}