import dayjs from "dayjs";
import { appConfig } from "../../config/Config";
import * as XLSX from "xlsx";

interface Document extends HTMLDocument {
  fullscreenElement: any;
  mozFullScreenElement?: any;
  msFullscreenElement?: any;
  webkitFullscreenElement?: any;
}

export class CommonUtils {
  public getAddDateList(fromDays: number, addDays: number) {
    const dayText: string[] = ["일", "월", "화", "수", "목", "금", "토"];

    const currentDay = dayjs();
    let arrCalDates: any[] = [];
    for (let i = fromDays; i > 0; i--) {
      const result: string = currentDay.add(i * -1, "d").format("YYYYMMDD");
      let result_day: string = dayText[parseInt(currentDay.add(i * -1, "d").format("d"))];
      const result_date: string = currentDay.add(i * -1, "d").format("D");
      arrCalDates.push({
        broad_date: result,
        broad_day: result_day,
        day_text: result_date,
      });
    }

    for (let i = 0; i < addDays; i++) {
      const result: string = currentDay.add(i, "d").format("YYYYMMDD");
      let result_day: string = dayText[parseInt(currentDay.add(i, "d").format("d"))];
      const result_date: string = currentDay.add(i, "d").format("D");
      if (i === 0) {
        result_day = "오늘";
      }
      arrCalDates.push({
        broad_date: result,
        broad_day: result_day,
        day_text: result_date,
      });
    }

    return arrCalDates;
  }

  public dateFormat = (value: string, iPattern: string, oPattern: string) => {
    return dayjs(value, iPattern).format(oPattern);
  };

  public convertSecToTime = (timeInSeconds: number) => {
    let hours: any = Math.floor(timeInSeconds / 3600);
    let minutes: any = Math.floor((timeInSeconds - hours * 3600) / 60);
    let seconds: any = Math.floor(timeInSeconds - hours * 3600 - minutes * 60);

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (hours > 99) {
      hours = this.numericComma(Number(hours));
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return {
      hours: "" + hours,
      minutes: "" + minutes,
      seconds: "" + seconds,
    };
  };

  public isEmptyObj(obj: any) {
    if (obj.constructor === Object && Object.keys(obj).length === 0) {
      return true;
    }

    return false;
  }

  public numericComma = (value: any) => {
    if (typeof value === "number") {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return undefined;
  };

  public copyToClipboard = (text: string) => {
    try {
      var textField = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // 최대공약수
  public gcd = (a: number, b: number) => {
    let gcb: number = 0;
    let num = [a, b];
    for (let i = 1; i <= Math.min.apply(null, num); i++) {
      if (Math.min.apply(null, num) % i === 0 && Math.max.apply(null, num) % i === 0) {
        gcb = i;
      }
    }
    return gcb;
  };

  public screenRatio = (a: number, b: number) => {
    const gcb = this.gcd(a, b);
    return [a / gcb, b / gcb];
  };

  // 전체화면 토글
  public toggelFullScreen = () => {
    if (this.isFullscreen()) {
      const doc: any = document;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        /* Firefox */
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        /* IE/Edge */
        doc.msExitFullscreen();
      }
    } else {
      let fullscreenElement: any = document.documentElement;
      if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
      } else if (fullscreenElement.mozRequestFullScreen) {
        /* Firefox */
        fullscreenElement.mozRequestFullScreen();
      } else if (fullscreenElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        fullscreenElement.webkitRequestFullscreen();
      } else if (fullscreenElement.msRequestFullscreen) {
        /* IE/Edge */
        fullscreenElement.msRequestFullscreen();
      }
    }
  };

  // 전체화면 지원여부
  public isSupportFullScreen = () => {
    let fullscreenElement: any = document.documentElement;
    if (
      fullscreenElement.requestFullscreen ||
      fullscreenElement.mozRequestFullScreen ||
      fullscreenElement.webkitRequestFullscreen ||
      fullscreenElement.msRequestFullscreen
    ) {
      return true;
    } else {
      return false;
    }
  };

  //현재 전체화면 인지
  public isFullscreen = () => {
    if (
      (document as Document).fullscreenElement ||
      (document as Document).webkitFullscreenElement ||
      (document as Document).mozFullScreenElement ||
      (document as Document).msFullscreenElement
    ) {
      return true;
    } else {
      return false;
    }
  };

  public maskingChar = (strCar: string) => {
    if (strCar === undefined || strCar === "") {
      return "";
    }
    if (appConfig.chat_id_mask) {
      let rtn_text: string = strCar.substring(0, 3) + "".padEnd(strCar.substring(3, 6).length, "*");
      if (strCar.length > 6) rtn_text = rtn_text + "...";
      return rtn_text;
    } else return strCar;
  };

  public convertBroadStartText = (startTime: string) => {
    const dayText: string[] = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
    const startDay = dayjs(startTime, "YYYYMMDDHHmmss").get("day");
    const startMinute = dayjs(startTime, "YYYYMMDDHHmmss").get("minute");
    const nowDay = dayjs().get("day");
    let startHour = dayjs(startTime, "YYYYMMDDHHmmss").get("hour");
    let startAmPm = "오전";
    if (startHour > 12) {
      startHour = startHour - 12;
      startAmPm = "오후";
    }

    let rtnDayText = dayText[startDay];
    if (startDay === nowDay) {
      rtnDayText = "오늘";
    }

    return rtnDayText + " " + startAmPm + " " + startHour.toString() + ":" + startMinute.toString().padStart(2, "0");
  };

  public calRemainSec = (startTime: string) => {
    const now = dayjs();
    const dateDiff = dayjs(startTime, "YYYYMMDDHHmmss").diff(now, "s");
    return dateDiff;
  };

  public convertRemainText = (inSec: number) => {
    let remain = "";
    if (Math.floor(inSec / (60 * 60 * 24)) > 0) {
      remain = remain + Math.floor(inSec / (60 * 60 * 24)) + "일 ";
    }

    if (remain !== "") remain = remain + "";
    remain =
      remain + Math.floor((inSec / (60 * 60)) % 24) + "시 " + Math.floor((inSec / 60) % 60) + "분 " + Math.floor(inSec % 60) + "초 남음";
    return remain;
  };

  public downloadExcel = (sheetContent: any, colWidth: any, sheetName: string, fileName: string) => {
    const sheet: any = XLSX.utils.aoa_to_sheet(sheetContent);
    sheet["!cols"] = colWidth;
    const chatFile = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(chatFile, sheet, sheetName);
    XLSX.writeFile(chatFile, fileName);
  };

  public downloadStatisticsExcel = (
    clickContent: any,
    statisticsContent: any,
    colWidth: any,
    clickSheetName: string,
    statisticsSheetName: string,
    fileName: string
  ) => {
    const clickSheet: any = XLSX.utils.aoa_to_sheet(clickContent);
    clickSheet["!cols"] = colWidth;

    const statisticsSheet: any = XLSX.utils.aoa_to_sheet(statisticsContent);
    statisticsSheet["!cols"] = colWidth;

    const chatFile = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(chatFile, clickSheet, clickSheetName);
    XLSX.utils.book_append_sheet(chatFile, statisticsSheet, statisticsSheetName);

    XLSX.writeFile(chatFile, fileName);
  };

  public getRandomNum = (min: number, max: number) => {
    let num = Math.floor(Math.random() * max + 1) + min;
    return num;
  };

  public calculateWindowSize = (windowWidth: number) => {
    if (windowWidth >= 1200) {
      return "lg";
    }
    if (windowWidth >= 992) {
      return "md";
    }
    if (windowWidth >= 768) {
      return "sm";
    }
    return "xs";
  };

  public getThresholdQuality = (list: any, threshold: number) => {
    let qualities: any = {};
    try {
      for (const quality of list) {
        let resolution = 0;
        if (quality.width < quality.height) resolution = quality.width;
        else resolution = quality.height;

        qualities[resolution] = quality;
      }

      let maxResolution = 0;
      Object.keys(qualities)
        .sort()
        // eslint-disable-next-line array-callback-return
        .map((key: any) => {
          if (key <= threshold) maxResolution = key;
        });

      return qualities[maxResolution];
    } catch (error) {
      return null;
    }
  };
}
